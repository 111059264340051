import {
  ApiTypesAddActivationsPostRequest,
  ApiTypesDeleteActivationsPostRequest,
} from '@bitkey-service/v2_core-types/lib/api/organization/activations/apiTypesActivations';

import {ApiActivations} from '@/api/core-legacy/organizations/activations/apiActivations';
import {catchStack} from '@/common/error/stacktrace';

export class ActivationService {
  public static add = (data: ApiTypesAddActivationsPostRequest) => ApiActivations.add(data).catch(catchStack());
  public static delete = (data: ApiTypesDeleteActivationsPostRequest) =>
    ApiActivations.delete(data).catch(catchStack());
}
