import {
  ApiTypesCreateOrgChargeDetailLabelPostRequest,
  ApiTypesCreateOrgChargeDetailLabelPostResponse,
  ApiTypesDeleteOrgChargeDetailLabelPostResponse,
  ApiTypesGetOrgChargeDetailLabelResponse,
  ApiTypesUpdateOrgChargeDetailLabelPatchRequest,
  ApiTypesUpdateOrgChargeDetailLabelPatchResponse,
} from '@bitkey-service/v2_core-types/lib/api/organization/charge-detail-label/apiTypesOrgChargeDetailLabel';

import {ApiChargeDetailLabel} from '@/api/core-legacy/organizations/charge-detail-label/apiChargeDetailLabel';

export class ChargeDetailLabelService {
  public getAll = (): Promise<ApiTypesGetOrgChargeDetailLabelResponse> => ApiChargeDetailLabel.getAll();
  public create = (
    data: ApiTypesCreateOrgChargeDetailLabelPostRequest
  ): Promise<ApiTypesCreateOrgChargeDetailLabelPostResponse> => ApiChargeDetailLabel.add(data);
  public deleteById = (id: string): Promise<ApiTypesDeleteOrgChargeDetailLabelPostResponse> =>
    ApiChargeDetailLabel.deleteById(id);
  public update = (
    data: ApiTypesUpdateOrgChargeDetailLabelPatchRequest
  ): Promise<ApiTypesUpdateOrgChargeDetailLabelPatchResponse> => ApiChargeDetailLabel.update(data);
}
