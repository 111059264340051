import {keepPreviousData, useQuery} from '@tanstack/react-query';
import {createFileRoute} from '@tanstack/react-router';
import {useMemo, useCallback, useState} from 'react';

import {getAnnouncementsApi, GetAnnouncementsQueryParam} from '@/api-call/workhub-core/getAnnouncementsApi';
import {useDebounceFilter} from '@/common/hooks/useDebounceFilter';
import useDict from '@/common/hooks/useDict';
import useWPagination from '@/common/hooks/useWPagination';
import {jpDate} from '@/common/jpDate';
import {Locale} from '@/common/redux/state-types/localeStateType';
import {CheckboxDropdown} from '@/components/dropdown';
import WHeaderNavigation, {type WHeaderActions} from '@/components/header/WHeaderNavigation';
import WHeaderSearch from '@/components/header/WHeaderSearch';
import {WFacetDateSearchProps} from '@/components/search/WFacetDateSearch';
import {WFacetSearchSelectModelsProps} from '@/components/search/WFacetSearchSelectModels';
import List from '@/features/building-tenant-management/announcement/List';
import {Authorize} from '@/routing/Authorize';

const dictDef = {
  title: {
    default: {
      default: 'お知らせ管理',
      [Locale.en_US]: 'Building Announcement Management',
    },
  },
  navigation: {
    default: {
      default: 'ビルテナント管理/',
      [Locale.en_US]: 'Building Tenant Management/',
    },
  },
  add: {
    default: {
      default: '新規追加',
      [Locale.en_US]: 'Add',
    },
  },
  searchByTitle: {
    default: {
      default: 'タイトル',
      [Locale.en_US]: 'Title',
    },
  },
  author: {
    default: {
      default: '作成者',
      [Locale.en_US]: 'Author',
    },
  },
  status: {
    default: {
      default: 'ステータス',
      [Locale.en_US]: 'Status',
    },
  },
  statusChoices: {
    default: {
      default: (status: 'published' | 'draft' | 'preparing' | 'scheduled') => {
        switch (status) {
          case 'published':
            return '公開';
          case 'draft':
            return '下書き';
          case 'preparing':
            return '公開準備';
          case 'scheduled':
            return '公開予約';
          default: {
            const _: never = status;
            return '';
          }
        }
      },
      [Locale.en_US]: (status: 'published' | 'draft' | 'preparing' | 'scheduled') => {
        switch (status) {
          case 'published':
            return 'Published';
          case 'draft':
            return 'Draft';
          case 'preparing':
            return 'Preparing';
          case 'scheduled':
            return 'Scheduled';
          default: {
            const _: never = status;
            return '';
          }
        }
      },
    },
  },
  publishedAt: {
    default: {
      default: '公開日時',
      [Locale.en_US]: 'Public Date',
    },
  },
};

export const Route = createFileRoute('/_authorized/building-tenant-management/announcement/')({
  component: () => (
    <Authorize featureGroup='BuildingTenantManagement' feature='BuildingAnnouncementManagement'>
      <RouteComponent />
    </Authorize>
  ),
});

function RouteComponent() {
  const dict = useDict(dictDef);
  const navigate = Route.useNavigate();

  const [searchWord, debouncedSearchWord, setDebouncedSearchWord] = useDebounceFilter('');
  const onChangeSearchWord = useCallback(
    (word: string) => {
      setDebouncedSearchWord(word);
    },
    [setDebouncedSearchWord]
  );
  const [isReset, setIsReset] = useState(false);
  const [selectedPersonaIds, setSelectedPersonaIds] = useState<string[]>([]);
  const [selectedStatuses, setSelectedStatuses] = useState<
    Required<Pick<GetAnnouncementsQueryParam, 'status'>>['status']
  >([]);
  const [selectedDateRange, setSelectedDateRange] = useState<{
    from?: number;
    to?: number;
  }>({from: undefined, to: undefined});
  const [publishedOrScheduledRange, setPublishedOrScheduledRange] = useState<
    Required<Pick<GetAnnouncementsQueryParam, 'publishedOrScheduledRange'>>['publishedOrScheduledRange']
  >({});

  const {offset, perPage, onNext, onPrev, handleChangePageSize} = useWPagination();

  const {data, isFetching} = useQuery({
    queryKey: [
      'building-tenant-management/announcement',
      {debouncedSearchWord, selectedPersonaIds, selectedStatuses, publishedOrScheduledRange},
      {offset, perPage},
    ],
    queryFn: async () => {
      const {data} = await getAnnouncementsApi({
        query: {
          offset,
          limit: perPage,
          freeword: debouncedSearchWord,
          createdByPersonaIds: selectedPersonaIds.length ? selectedPersonaIds : undefined,
          status: selectedStatuses,
          publishedOrScheduledRange,
        },
      });
      return data;
    },
    placeholderData: keepPreviousData, // ページを変更したときに前のデータを残す
  });

  const actions = useMemo<WHeaderActions>(
    () => ({
      primary: {
        label: dict.add,
        action: () => {
          navigate({to: '/building-tenant-management/announcement/create'});
        },
      },
    }),
    [dict.add, navigate]
  );

  const peopleFacet = useMemo<WFacetSearchSelectModelsProps>(
    () => ({
      model: 'people',
      label: dict.author,
      searchableLabels: [dict.author],
      selectedIds: selectedPersonaIds,
      onCheckStateChanged: (selectedIds: string[]) => {
        if (isReset) setIsReset(false);
        setSelectedPersonaIds(selectedIds);
      },
      filter: {
        type: {equals: 'Member'},
      },
      option: {idColumn: 'personaId'},
      checkType: 'multi',
      disable: false,
      reset: isReset,
    }),
    [dict.author, selectedPersonaIds, isReset]
  );

  const statusSelectedFacets = useMemo<JSX.Element>(
    () => (
      <CheckboxDropdown
        label={dict.status}
        items={[
          {
            value: 'published',
            label: dict.statusChoices('published'),
          },
          {
            value: 'draft',
            label: dict.statusChoices('draft'),
          },
          {
            value: 'preparing',
            label: dict.statusChoices('preparing'),
          },
          {
            value: 'scheduled',
            label: dict.statusChoices('scheduled'),
          },
        ]}
        selected={selectedStatuses}
        onChange={setSelectedStatuses}
        color={!selectedStatuses.length ? 'secondary' : 'primary'}
      />
    ),
    [dict, selectedStatuses]
  );

  const publishedOrScheduledDateFacet = useMemo<WFacetDateSearchProps>(
    () => ({
      label: dict.publishedAt,
      startDate: selectedDateRange.from,
      endDate: selectedDateRange.to,
      onChangeStartDate: from => setSelectedDateRange(current => ({...current, from})),
      onChangeEndDate: to => setSelectedDateRange(current => ({...current, to})),
      onClickFilter: () =>
        setPublishedOrScheduledRange({
          from: selectedDateRange?.from ? jpDate(selectedDateRange.from).toISOString() : undefined,
          to: selectedDateRange?.to ? jpDate(selectedDateRange.to).toISOString() : undefined,
        }),
      onClickClear: () => {
        setSelectedDateRange({});
        setPublishedOrScheduledRange({});
      },
    }),
    [dict.publishedAt, selectedDateRange.from, selectedDateRange.to]
  );

  const onReset = useCallback(() => {
    setSelectedPersonaIds([]);
    setDebouncedSearchWord('');
    setSelectedStatuses([]);
    setSelectedDateRange({});
    setPublishedOrScheduledRange({});
    setIsReset(true);
  }, [setDebouncedSearchWord]);

  return (
    <>
      <WHeaderNavigation title={dict.title} navigation={dict.navigation} actions={actions} />
      <WHeaderSearch
        searchableLabels={[dict.searchByTitle]}
        facets={[peopleFacet, statusSelectedFacets, publishedOrScheduledDateFacet]}
        updateSearchWord={onChangeSearchWord}
        searchWord={searchWord}
        onReset={onReset}
      />
      <List
        list={data?.items}
        fetching={isFetching}
        totalCount={data?.totalCount}
        onPrev={onPrev}
        onNext={onNext}
        onChangePageSize={handleChangePageSize}
      />
    </>
  );
}
