export class FirebaseFunctions {
  public static makePath = (a: string, b: string) => {
    if (!a.startsWith('/')) {
      a = '/' + a;
    }
    if (!b.startsWith('/')) {
      b = '/' + b;
    }
    return a + b;
  };

  // token期限切れの場合にログイン画面に戻してsnackbar出すための関数
  public static logoutByExpired?: () => void;
  public static setLogoutByExpired = (logoutByExpired: () => void) =>
    (FirebaseFunctions.logoutByExpired = logoutByExpired);
}
