import type {FsActivation} from '@/firestore/types/fsActivationType';
import type {FsAuthorityPattern} from '@/firestore/types/fsAuthorityPatternType';

import {ValueOf} from '../utils/typeUtils';

export type FeatureAuthority = FsAuthorityPattern['authority'];
export type Feature = keyof FeatureAuthority;

export const Feature = {
  Announcement: 'announcement',
  Mypage: 'mypage',
  Dashboard: 'dashboard',
  Building: 'building',
  CompanyInformation: 'companyInformation',
  Bitlock: 'bitlock',
  BitlockGate: 'bitlockGate',
  Bitreader: 'bitreader',
  Bitlink: 'bitlink',
  FaceTablet: 'faceTablet',
  SecurityCamera: 'securityCamera', // 顔検出用カメラ (他にもあるかもなので)
  Floor: 'floor',
  Room: 'room',
  // 表示設定は後で
  Face: 'face', // 顔情報一覧とか
  Nfc: 'nfc',
  // NfcGroup: 'nfcGroup', // グループ見れるなら個別のNFCも見れるんちゃう、と思う。これくらいは一緒でいいかも
  UnlockConditionTemplate: 'unlockConditionTemplate',
  // 勤怠と入退室は別じゃない？と思う。勤怠は何をもって出勤とするか、っていう設定を別で持って、そっちに特化した画面になる感じ。
  Attendance: 'attendance',
  EntryExit: 'entryExit',
  Users: 'users', // ユーザー一覧を全部見れる。すぐに細分化したくなる気がするけど取り急ぎ。やるとしても詳細設定でいいかも。フィルタ設定的な。
  Authority: 'authority', // この中でも自分の権限以上の権限は扱えない制御が必要。機能追加時に死ぬのでフル権限っていうのは別で作る。
  UnlockLog: 'unlockLog',
  Reception: 'reception',
  ReceptionSetting: 'receptionSetting',
  LinkageSetting: 'linkageSetting',
  SSOSetting: 'SSOSetting',
  SecurityModeSetting: 'SecurityModeSetting',
  AutoSecurityModeSetting: 'AutoSecurityModeSetting',
  ReceptionSettingTemplate: 'receptionSettingTemplate',
  CloudCamera: 'cloudCamera',
  ReservationCalendar: 'reservationCalendar',
  ReservationCalendarAddPeopleCsv: 'reservationCalendarAddPeopleCsv',
  ReservationCalendarMySchedule: 'reservationCalendarMySchedule',
  ReservationThingCalendar: 'reservationThingCalendar',
  ReservationLog: 'reservationLog',
  CoworkingReservationCalendar: 'coworkingReservationCalendar',
  ReservationSetting: 'reservationSetting',
  CongestionStatus: 'congestionStatus',
  Subscriptions: 'subscriptions',
  EventReservation: 'eventReservation',
  EventReservationParticipants: 'eventReservationParticipants',
  EventReservationList: 'eventReservationList',
  EventReservationDashboard: 'EventReservationDashboard',
  // ここからV2
  V2Regions: 'v2Regions',
  V2Buildings: 'v2Buildings',
  V2Floors: 'v2Floors',
  V2Areas: 'v2Areas',
  V2Workspots: 'v2Workspots',
  V2Layouts: 'v2Layouts',
  AntiPassBackArea: 'antiPassBackArea',
  AntiPassBackAlert: 'antiPassBackAlert',
  V2SecurityDevices: 'v2SecurityDevices',
  V2SecurityDeviceAlert: 'v2SecurityDeviceAlert',
  V2SecurityCards: 'v2SecurityCards',
  V2TemporaryCardGroups: 'v2TemporaryCardGroups',
  V2SecurityCardGroups: 'v2SecurityCardGroups',
  V2Equipments: 'v2Equipments',
  V2Lockers: 'V2Lockers',
  // ロッカー予約用の権限。Appで明確に権限を分けたいのでわけました
  V2LockerReservationCalendar: 'v2LockerReservationCalendar',
  Shelves: 'shelves',
  V2Members: 'v2Members',
  V2OfficeDepartments: 'v2OfficeDepartments',
  V2Positions: 'v2Positions',
  V2EmploymentStatuses: 'v2EmploymentStatuses',
  V2EmployeeGroupSettings: 'v2EmployeeGroupSettings',
  V2EmployeesAttendance: 'v2EmployeesAttendance',
  V2LocationSearch: 'v2LocationSearch',
  V2Visitors: 'v2Visitors',
  V2Casts: 'v2Casts',
  V2CastGroups: 'v2CastGroups',
  V2Customers: 'v2Customers',
  V2AccessRights: 'v2AccessRights',
  PasscodeGroups: 'PasscodeGroups',
  V2KeyBundles: 'v2KeyBundles',
  V2MailTemplates: 'V2MailTemplates',
  V2Notification: 'V2Notification',
  V2OrganizationSetting: 'V2OrganizationSetting',
  V2Reception: 'V2Reception',
  V2ReceptionData: 'V2ReceptionData',
  V2ReceptionGuest: 'V2ReceptionGuest',
  V2Manual: 'V2Manual',
  AboutBitkey: 'AboutBitkey',

  // V2Signage
  SignageEmployeeAttendance: 'signageEmployeeAttendance',
  SignageCheckInInformation: 'signageCheckInInformation',
  SignageTrafficCondition: 'signageTrafficCondition',
  FaceRegistration: 'FaceRegistration',
  TrafficConditions: 'trafficConditions',
  // bitface with 検温ソリューションが収集した検温データを閲覧できるか
  V2ReadTemperatureLogs: 'v2ReadTemperatureLogs',
  V2ServiceApplication: 'v2serviceApplication',

  BmMigrateAssistant: 'BmMigrateAssistant',
  BmMigrateTour: 'BmMigrateTour',

  //bitlock App
  V2BitlockApp: 'V2BitlockApp',
  V2ReceptionApp: 'V2ReceptionApp',

  // workhub App
  V2WorkhubAppOrganization: 'V2WorkhubAppOrganization', // アプリの組織タブの表示非表示
  WorkhubAppPersonalizedNfcCard: 'WorkhubAppPersonalizedNfcCard', // アプリのマイページから管理できるログインユーザー個人所有のNFCカード管理権限
  WorkhubAppDeviceSetting: 'WorkhubAppDeviceSetting', //アプリ向けデバイス権限
  WorkhubAppLinkageDeviceSetting: 'WorkhubAppLinkageDeviceSetting', //デバイス連携権限 reader,link共通
  // bitlock
  WorkhubAppInstallBitlockPro2: 'WorkhubAppInstallBitlockPro2',
  WorkhubAppInstallBitlockGate: 'WorkhubAppInstallBitlockGate',
  WorkhubAppUninstallBitlockSeries: 'WorkhubAppUninstallBitlockSeries',
  WorkhubAppFirmwareUpdateBitlockSeries: 'WorkhubAppFirmwareUpdateBitlockSeries',
  WorkhubAppViewLogsBitlockSeries: 'WorkhubAppViewLogsBitlockSeries',
  WorkhubAppSetUnlockPositionBitlockSeries: 'WorkhubAppSetUnlockPositionBitlockSeries',
  WorkhubAppAutoLockSettingBitlockSeries: 'WorkhubAppAutoLockSettingBitlockSeries',
  WorkhubAppTouchSensorSettingBitlockSeries: 'WorkhubAppTouchSensorSettingBitlockSeries',
  // bitreader
  WorkhubAppInstallBitreaderPlus: 'WorkhubAppInstallBitreaderPlus',
  WorkhubAppUninstallBitreaderSeries: 'WorkhubAppUninstallBitreaderSeries',
  WorkhubAppFirmwareUpdateBitreaderSeries: 'WorkhubAppFirmwareUpdateBitreaderSeries',
  WorkhubAppViewLogsBitreaderSeries: 'WorkhubAppViewLogsBitreaderSeries',
  WorkhubAppSwitchValidLockUnlockSettingBitreaderSeries: 'WorkhubAppSwitchValidLockUnlockSettingBitreaderSeries',
  WorkhubAppEnableNfcSettingBitreaderSeries: 'WorkhubAppEnableNfcSettingBitreaderSeries',
  WorkhubAppEnableKeyPadSettingBitreaderSeries: 'WorkhubAppEnableKeyPadSettingBitreaderSeries',
  WorkhubAppEnableLedSettingBitreaderSeries: 'WorkhubAppEnableLedSettingBitreaderSeries',
  WorkhubAppBatteryAlertSettingBitreaderSeries: 'WorkhubAppBatteryAlertSettingBitreaderSeries',
  WorkhubAppPasscodeSettingBitreaderSeries: 'WorkhubAppPasscodeSettingBitreaderSeries',
  // bitlink
  WorkhubAppInstallBitlinkSeries: 'WorkhubAppInstallBitlinkSeries',
  WorkhubAppUninstallBitlinkSeries: 'WorkhubAppUninstallBitlinkSeries',
  WorkhubAppFirmwareUpdateBitlinkSeries: 'WorkhubAppFirmwareUpdateBitlinkSeries',
  WorkhubAppEnableBeaconSettingBitlinkSeries: 'WorkhubAppEnableBeaconSettingBitlinkSeries',
  WorkhubAppChangeWiFiConnectionSettingBitlinkSeries: 'WorkhubAppChangeWiFiConnectionSettingBitlinkSeries',

  Report: 'report',
  SendMailJob: 'sendMailJob',
  AppKeys: 'AppKeys',
  FaceRecognitionStatus: 'faceRecognitionStatus',
  InviteMailSettingManager: 'InviteMailSettingManager',

  // workhubとBCEをつなげるための設定用Activation、基本的には必要ない限り使用しないこと
  ThirdPlaceBceSetting: 'ThirdPlaceBceSetting',

  /**
   * V2マルチテナント オーナー用のActivation
   * ThirdPlaceで契約しているエリアに対して共用表示が表示されるようになったりします
   */
  V2MultiTenantOwner: 'V2MultiTenantOwner',
  V2MultiTenantOwnerContract: 'V2MultiTenantOwnerContract',
  V2MultiTenantOwnerContractPlan: 'V2MultiTenantOwnerContractPlan',

  NotificationSetting: 'NotificationSetting',

  DataImport: 'DataImport',
  ForBitkey: 'ForBitkey',
  V2Dashboard: 'v2Dashboard',
  ThirdPlace: 'ThirdPlace',
  ThirdPlaceContract: 'ThirdPlaceContract',
  ThirdPlaceContractPlans: 'ThirdPlaceContractPlans',
  ThirdPlaceUpdateRules: 'ThirdPlaceUpdateRules',
  ThirdPlaceCancelRules: 'ThirdPlaceCancelRules',
  ThirdPlaceOptions: 'ThirdPlaceOptions',
  ThirdPlaceBilling: 'ThirdPlaceBilling',
  ThirdPlaceReceivedMoney: 'ThirdPlaceReceivedMoney',
  ThirdPlaceRefund: 'ThirdPlaceRefund',
  ThirdPlaceClaimCycles: 'ThirdPlaceClaimCycles',
  ThirdPlaceInvoice: 'ThirdPlaceInvoice',
  ThirdPlaceGuest: 'ThirdPlaceGuest',
  ThirdPlaceCustomer: 'ThirdPlaceCustomer',
  ThirdPlacePaymentMethod: 'ThirdPlacePaymentMethod',
  ThirdPlacePassSite: 'ThirdPlacePassSite',
  LocationInformationCSVOutput: 'LocationInformationCSVOutput',
  Application: 'Application',
  SecuritySetting: 'SecuritySetting',

  DropInPlans: 'DropInPlans',
  DropInPricePlans: 'DropInPricePlans',

  SpaceAuthoritySetting: 'spaceAuthoritySetting',
  WorkBooth: 'WorkBooth',
  AzbilCloudOperation: 'AzbilCloudOperation',
  TabletOperation: 'TabletOperation',
  DeleteFaceInformationForGuest: 'DeleteFaceInformationForGuest',
  FMDashboard: 'FMDashboard',
  OutlookReservationSyncHistories: 'outlookReservationSyncHistories',
  GoogleCalendarSyncHistories: 'GoogleCalendarSyncHistories',

  // ラキールWFの来訪申請メニュー
  // 2024/11/29時点で京セラ様利用のみを想定
  ApplicantTaskList: 'ApplicantTaskList',
  ApproverTaskList: 'ApproverTaskList',
  ApplicationFormList: 'ApplicationFormList',

  //ビルテナントサービス
  BuildingTenantService: 'BuildingTenantService',

  //ビルテナント管理
  BuildingTenantServiceManagement: 'BuildingTenantServiceManagement',
  BuildingAnnouncementManagement: 'BuildingAnnouncementManagement',
} as const satisfies Record<string, Feature>; // バックエンドで定義していない feature を勝手に使わないよう制約

export const FeatureGroup = {
  Announcement: 'Announcement',
  Dashboard: 'Dashboard',
  CompanyInformation: 'CompanyInformation',
  Subscriptions: 'Subscriptions',
  BuildingManagement: 'BuildingManagement',
  Department: 'Department',
  Bitlock: 'Bitlock',
  Bitreader: 'Bitreader',
  Tablet: 'Tablet',
  CameraSecurity: 'CameraSecurity',
  CloudCamera: 'CloudCamera',
  Location: 'Location',
  Heatmap: 'Heatmap',
  Locker: 'Locker',
  Shelf: 'Shelf',
  Cabinet: 'Cabinet',
  Face: 'Face',
  Nfc: 'Nfc',
  KeyConditionTemplate: 'KeyConditionTemplate',
  AttendanceEntryExit: 'AttendanceEntryExit',
  Member: 'Member',
  Guest: 'Guest',
  Authority: 'Authority',
  Reception: 'Reception',
  ReservationCalendar: 'ReservationCalendar',
  ReservationCalendarAddPeopleCsv: 'reservationCalendarAddPeopleCsv',
  CoworkingReservationCalendar: 'CoworkingReservationCalendar',
  // これは検証終わったら消す
  ReservationCalendar_bk: 'ReservationCalendar_bk',
  SystemLinkage: 'SystemLinkage',
  CongestionMap: 'CongestionMap',
  Bitlink: 'Bitlink',
  Events: 'Events',
  AntiPassBack: 'AntiPassBack',
  V2: 'V2', // 暫定
  V2Spaces: 'V2Spaces',
  V2Workspot: 'V2Workspot',
  V2Layouts: 'V2Layouts',
  V2SecurityDevices: 'V2SecurityDevices',
  V2SecurityCards: 'V2SecurityCards',
  V2SecurityCardGroups: 'V2SecurityCardGroups',
  V2Equipment: 'V2Equipment',
  V2Locker: 'V2Locker',
  V2MemberManagement: 'V2MemberManagement',
  FaceRegistration: 'FaceRegistration',
  V2VisitorManagement: 'V2VisitorManagement',
  V2VisitorGroupManagement: 'V2VisitorGroupManagement',
  V2CastManagement: 'V2CastManagement',
  V2CustomerManagement: 'V2CustomerManagement',
  V2AccessControl: 'V2AccessControl',
  V2MailTemplates: 'V2MailTemplates',
  V2Notification: 'V2Notification',
  V2OrganizationSetting: 'V2OrganizationSetting',
  V2Reception: 'V2Reception',
  V2Manual: 'V2Manual',
  V2ServiceApplication: 'V2ServiceApplication',
  AboutBitkey: 'AboutBitkey',
  V2Signage: 'V2Signage',
  TrafficConditions: 'TrafficConditions',
  V2LocationSearch: 'V2LocationSearch',
  V2EmployeesAttendanceReport: 'V2EmployeesAttendanceReport',

  BmMigrateAssistant: 'BmMigrateAssistant',
  BmMigrateTour: 'BmMigrateTour',

  // bitlock APPを利用する組織はこれをonにする
  V2BitlockApp: 'V2BitlockApp',

  //受付タブレットを使用する組織はonにする
  V2ReceptionApp: 'V2ReceptionApp',

  // 開発用
  Development: 'Development',

  // ThirdPlace利用組織
  ThirdPlace: 'ThirdPlace',
  Report: 'Report',

  // 招待メールを編集するためのアクティベーション、基本的には必要ない限り使用しないこと
  InviteMailSettingManager: 'InviteMailSettingManager',

  // workhubとBCEをつなげるための設定用Activation、基本的には必要ない限り使用しないこと
  ThirdPlaceBceSetting: 'ThirdPlaceBceSetting',

  DataImport: 'DataImport',

  /**
   * V2マルチテナント オーナー用のActivation
   * ThirdPlaceで契約しているエリアに対して共用表示が表示されるようになったりします
   */
  V2MultiTenantOwner: 'V2MultiTenantOwner',

  ForBitkey: 'ForBitkey',
  // beacon向け
  // 個人に対しての制御はする必要がないので、activationだけで制御
  Beacon: 'Beacon',
  V2Dashboard: 'v2Dashboard',

  //申請
  Application: 'Application',

  // Googleカレンダー連携のサービス
  GoogleCalendarSync: 'GoogleCalendarSync',

  // IPアドレス設定
  SecuritySetting: 'SecuritySetting',

  //スペース制御
  SpaceAuthoritySetting: 'SpaceAuthoritySetting',

  // ワークブース
  WorkBooth: 'WorkBooth',

  AzbilCloudOperation: 'AzbilCloudOperation',

  // RoomSupportやReceptionをworkhubから操作できる
  TabletOperation: 'TabletOperation',

  // 外部サービス連携
  LinkageSetting: 'LinkageSetting',

  // SSO連携
  SSOSetting: 'SSOSetting',

  // 警備モード
  SecurityModeSetting: 'SecurityModeSetting',
  AutoSecurityModeSetting: 'AutoSecurityModeSetting',

  // 勤怠
  // TODO: 出社状況画面の扱いをどうするか検討
  Attendance: 'Attendance',

  // 顔情報削除
  DeleteFaceInformationForGuest: 'DeleteFaceInformationForGuest',

  // ファシリティマネジメントダッシュボード
  FMDashboard: 'FMDashboard',
  OutlookReservationSyncHistories: 'OutlookReservationSyncHistories',

  // ラキールWFの来訪申請メニュー
  // 2024/11/29時点で京セラ様利用のみを想定
  VisitApplication: 'VisitApplication',

  //ビルテナントサービス
  BuildingTenantService: 'BuildingTenantService',

  // ビルテナント管理
  BuildingTenantManagement: 'BuildingTenantManagement',
} as const;
export type FeatureGroup = ValueOf<typeof FeatureGroup>;

export type ActivationGroup = FsActivation['activationGroup'];

// パッケージングされた機能群の考え方で定義。
// まずは管理画面でしか制御に使わない。アプリはあと。
// ○○パッケージを契約してたらこれらを使えます、的なノリ
// MECEは気にしない（基本的にどれかあったらいくつかが使えるようになるイメージで、n:nの関係にする）
export const ActivationGroup = {
  // 共通機能も使えないような制御のためにcommonも作る。coworkingテナントは空間が専用のビューになるとか、今後お試し期間やるとか、色々ありうるので
  Common: 'common',

  // 提供先業種でパッケージングされている機能群 (売り方)
  Office: 'office',

  // 業種とは違うけど、同じように利用方法でのグルーピング
  CameraSecurity: 'cameraSecurity',
  CloudCamera: 'cloudCamera',

  BuildingControl: 'buildingControl',
  Workspot: 'workspot',

  // coworkingテナント用機能群
  CoworkingTenant: 'coworkingTenant',

  // 設置されたらactivate、みたいにしたいやつ。activateされるまでは設置機能は出るけど一覧画面とかは出ないイメージ。
  // 設置のコントロールは必要があれば別途（はじめて購入したらactivateみたいな）
  Bitlock: 'bitlock',
  Bitreader: 'bitreader', // これかビルコントロールのどちらかを有効にするとNFCもついてくる感じ
  Bitlink: 'bitlink',
  Locker: 'locker',
  Shelf: 'shelf',

  // 契約に基づいて個別にActivateされるもの
  Reception: 'reception', // 受付システムを契約してる場合
  V2Reception: 'v2reception', // 受付システムを契約してる場合
  Face: 'face', // 顔認証システムを契約してる場合
  /** @deprecated 2023年7月現在、`reservationCalendar`さえ有効にすれば予約機能が解放されるという運用なので不要になる。削除予定 */
  Calendar: 'calendar',
  /** @deprecated 2023年7月現在、`reservationCalendar`さえ有効にすれば予約機能が解放されるという運用なので不要になる。削除予定 */
  Reservation: 'reservation',

  // うちの都合で出し分けるやつ
  Member: 'member',
  Events: 'events',
  Layouts: 'layouts',
  Equipment: 'equipment',
  MemberManagement: 'memberManagement',
  FaceRegistration: 'faceRegistration',
  VisitorManagement: 'visitorManagement',
  VisitorGroupManagement: 'visitorGroupManagement',
  CastManagement: 'castManagement',
  CustomerManagement: 'customerManagement',
  ReservationCalendar: 'reservationCalendar',
  ReservationCalendar_bk: 'reservationCalendar_bk',
  CoworkingReservationCalendar: 'coworkingReservationCalendar',
  Announcement: 'announcement',
  Manual: 'manual',
  V2ServiceApplication: 'v2serviceApplication',
  V2SecurityCardGroups: 'v2securityCardGroups', //現時点ではbM移行組織
  AboutBitkey: 'aboutBitkey',
  V2Signage: 'v2Signage',
  TrafficConditions: 'trafficConditions',

  BmMigrateAssistant: 'bMmigrateAssistant',
  BmMigrateTour: 'bMmigrateTour',
  V2Notification: 'v2Notification',
  V2OrganizationSetting: 'v2OrganizationSetting',

  V2BitlockApp: 'v2BitlockApp',
  V2ReceptionApp: 'v2ReceptionApp',

  Report: 'report',
  InviteMailSettingManager: 'inviteMailSettingManager',
  // workhubとBCEをつなげるための設定用Activation、基本的には必要ない限り使用しないこと
  ThirdPlaceBceSetting: 'thirdPlaceBceSetting',

  DataImport: 'dataImport',
  ForBitkey: 'forBitkey',
  // beacon向けWSelectPlan
  // 個人に対しての制御はする必要がないので、activationだけで制御
  Beacon: 'Beacon',
  V2Dashboard: 'v2Dashboard',
  V2EmployeesAttendanceReport: 'V2EmployeesAttendanceReport',
  // ある程度パッケージングした上でDeactivationとか作ってもいいかなと思ったけど複雑になるからやめる // 除外設定入れないと思いきったパッケージングができなくてつらそう
  ThirdPlace: 'thirdPlace',

  Application: 'application',

  /**
   * V2マルチテナント オーナー用のActivation
   * ThirdPlaceで契約しているエリアに対して共用表示が表示されるようになったりします
   */
  V2MultiTenantOwner: 'v2MultiTenantOwner',

  GoogleCalendarSync: 'googleCalendarSync',

  SecuritySetting: 'SecuritySetting',

  MultiLockDevices: 'MultiLockDevices',

  SpaceAuthoritySetting: 'spaceAuthoritySetting',
  WorkBooth: 'workBooth',

  AzbilCloudOperation: 'azbilCloudOperation',

  TabletOperation: 'tabletOperation',

  Attendance: 'attendance',
  Wowtalk: 'wowtalk',
  Pifaa: 'pifaa',
  Jobcan: 'jobcan',

  FMDashboard: 'FMDashboard',

  SSOSetting: 'SSOSetting',

  AntiPassBack: 'antiPassBack',
  OutlookReservationSyncHistories: 'outlookReservationSyncHistories',

  // ラキールWFの来訪申請メニュー
  // 2024/11/29時点で京セラ様利用のみを想定
  VisitApplication: 'visitApplication',

  //ビルテナントサービス
  BuildingTenantService: 'buildingTenantService',

  //ビルテナント管理
  BuildingTenantManagement: 'buildingTenantManagement',

  //Outlookアドイン用のアクティベーション
  //現在これによって制御するのはエリア詳細画面>受付用備考テンプレートのみ
  outlookAddInSetting: 'outlookAddInSetting',

  SecurityModeSetting: 'securityModeSetting',
  AutoSecurityModeSetting: 'autoSecurityModeSetting',
} as const satisfies Record<string, ActivationGroup>; // バックエンドで定義していない feature を勝手に使わないよう制約

// 本命の定義
// featureGroupを有効化するために、activationGroupのどれかが有効になっていたらOK、という作り。空配列は未リリース。
// ただこいつらは本来APIに対しても有効にする必要があるので、画面というより機能という観点が必要。
export const ActivationFeatureGroupMap: {[K in FeatureGroup]: ActivationGroup[]} = {
  // 普通に共通
  Announcement: ['announcement'],
  Authority: ['common', 'member', 'memberManagement'],

  Bitlock: ['bitlock'],
  Bitreader: ['bitreader'],
  BuildingManagement: ['common'],
  ReservationCalendar: ['reservationCalendar'], // 新しいほう
  ReservationCalendar_bk: ['reservationCalendar_bk'], // 既存動作確認用
  CoworkingReservationCalendar: ['coworkingReservationCalendar'], // コワーキングテナント用
  CameraSecurity: ['cameraSecurity'],
  CloudCamera: ['cloudCamera'],
  CompanyInformation: [], // まだ出さない
  CongestionMap: ['cameraSecurity'], // readerとかフラッパーゲートとかでも管理できたりするからCameraSecurityではないけど一旦。
  AttendanceEntryExit: ['cameraSecurity'], // 名前がヤバイ
  Dashboard: [], // まだ出さない
  Department: ['office', 'member'], // そのうちtenantも？
  Face: ['face'],
  Guest: ['common'],
  Member: ['common', 'member', 'coworkingTenant'],
  Heatmap: [],
  KeyConditionTemplate: [],
  Location: ['common'],
  Locker: ['locker'],
  Shelf: ['shelf'],
  Cabinet: [],
  Nfc: ['bitreader'],
  Reception: ['reception'],

  Subscriptions: [], // まだ出さない
  SystemLinkage: [], // まだ出さない
  Tablet: ['face'],
  Bitlink: ['bitlink'],
  Events: ['events'],
  TrafficConditions: ['trafficConditions'],
  V2LocationSearch: ['reservationCalendar', 'Beacon'],
  V2: ['V2' as ActivationGroup],
  Development: [],
  // [FeatureGroup.Layout]: ['layout' as ActivationGroup], // TODO serverside
  V2Spaces: ['buildingControl'],
  V2Workspot: ['workspot'], // 暫定
  V2Layouts: ['layouts'],
  AntiPassBack: ['antiPassBack'],
  V2SecurityDevices: ['buildingControl'],
  V2SecurityCards: ['buildingControl'],
  V2SecurityCardGroups: ['v2securityCardGroups'],
  V2Equipment: ['equipment'],
  V2MemberManagement: ['memberManagement'],
  FaceRegistration: ['faceRegistration'],
  V2VisitorManagement: ['visitorManagement'],
  V2VisitorGroupManagement: ['visitorGroupManagement'], //初期ではv1-v2移行向けの機能のため一旦分割
  V2CastManagement: ['castManagement'],
  V2CustomerManagement: ['customerManagement'],
  V2AccessControl: ['buildingControl'],
  V2Locker: ['locker'],
  V2MailTemplates: ['reservationCalendar'],
  V2Notification: ['v2Notification'],
  V2OrganizationSetting: ['v2OrganizationSetting'],
  V2Reception: ['v2reception'],
  V2Manual: ['manual'],
  V2ServiceApplication: ['v2serviceApplication'], //同上、テナント組織側にしか付与されない
  AboutBitkey: ['bMmigrateTour', 'memberManagement'],
  V2Signage: ['v2Signage'],
  V2EmployeesAttendanceReport: ['V2EmployeesAttendanceReport'],

  BmMigrateAssistant: ['bMmigrateAssistant'],
  BmMigrateTour: ['bMmigrateTour'],
  V2BitlockApp: ['v2BitlockApp'],
  V2ReceptionApp: ['v2ReceptionApp'],
  Report: ['report'],
  InviteMailSettingManager: ['inviteMailSettingManager'],
  ThirdPlaceBceSetting: ['thirdPlaceBceSetting'],
  ForBitkey: ['forBitkey'],
  Beacon: ['Beacon'],
  v2Dashboard: ['v2Dashboard'],
  DataImport: ['dataImport'],

  V2MultiTenantOwner: ['v2MultiTenantOwner'],
  Application: ['application'],

  ThirdPlace: ['thirdPlace'],
  GoogleCalendarSync: ['googleCalendarSync'],
  SecuritySetting: ['SecuritySetting'],
  SpaceAuthoritySetting: ['spaceAuthoritySetting'],
  WorkBooth: ['workBooth'],
  AzbilCloudOperation: ['azbilCloudOperation'],
  TabletOperation: ['tabletOperation'],

  LinkageSetting: ['v2reception', 'attendance', 'pifaa'],
  SSOSetting: ['SSOSetting'],
  Attendance: ['attendance'],
  DeleteFaceInformationForGuest: ['visitorManagement'],
  FMDashboard: ['FMDashboard'],
  reservationCalendarAddPeopleCsv: ['reservationCalendar'],
  OutlookReservationSyncHistories: ['outlookReservationSyncHistories'],

  // ラキールWFの来訪申請メニュー
  // 2024/11/29時点で京セラ様利用のみを想定
  VisitApplication: ['visitApplication'],

  //ビルテナントサービス
  BuildingTenantService: ['buildingTenantService'],

  //ビルテナント管理
  BuildingTenantManagement: ['buildingTenantManagement'],

  SecurityModeSetting: ['securityModeSetting'],
  AutoSecurityModeSetting: ['autoSecurityModeSetting'],
};
