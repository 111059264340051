import {NestedPartial} from '@bitkey-service/workhub-functions-common-types/lib/common/typeUtils';
import {createSlice} from '@reduxjs/toolkit';

import {AnnouncementState} from '../state-types/announcementState';

const initialState: AnnouncementState = {
  items: [],
  totalCount: 0,
  unreadCount: 0,
};

const announcementSlice = createSlice({
  name: 'announcement',
  initialState,
  reducers: {
    set: (state, action: {payload: NestedPartial<AnnouncementState>}) => ({...state, ...action.payload}),
    read: (state, action: {payload: {id: string}}) => {
      return {
        items: state.items.map(announcement =>
          announcement.id === action.payload.id
            ? {
                ...announcement,
                read: true,
              }
            : announcement
        ),
        totalCount: state.totalCount,
        unreadCount: state.unreadCount - 1,
      };
    },
    clear: () => initialState,
  },
});

export default announcementSlice;
