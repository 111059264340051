import styled from '@emotion/styled';
import {useQuery, useQueryClient} from '@tanstack/react-query';
import {createFileRoute} from '@tanstack/react-router';
import {useCallback, useMemo, useState} from 'react';

import {
  createSecurityModeSettingApi,
  CreateSecurityModeSettingBody,
} from '@/api-call/workhub-core/createSecurityModeSettingApi';
import {deleteSecurityModeSettingApi} from '@/api-call/workhub-core/deleteSecurityModeSettingApi';
import {getSecurityModeSettingApi} from '@/api-call/workhub-core/getSecurityModeSettingApi';
import {
  updateSecurityModeSettingApi,
  UpdateSecurityModeSettingBody,
} from '@/api-call/workhub-core/updateSecurityModeSettingApi';
import {FeatureControlHelper} from '@/common/feature-control/helpers/featureControlHelper';
import useDict, {useCommonDict} from '@/common/hooks/useDict';
import {useLocale} from '@/common/hooks/useLocale';
import {useLoginUser} from '@/common/hooks/useLoginUser';
import Logger from '@/common/logger/logger';
import {Locale} from '@/common/redux/state-types/localeStateType';
import {WHFontCss} from '@/common/styles/whFont';
import {LocaleUtils} from '@/common/utils/localeUtils';
import WAlert from '@/components/alert/WAlert';
import WDialog from '@/components/dialog/WDialog';
import WLoadingComponent from '@/components/figma/others/stepper/WLoadingComponent';
import WHeaderNavigation, {WHeaderActions} from '@/components/header/WHeaderNavigation';
import {fsSpaceGetById} from '@/firestore/query/fsSpaceQuery';
import {Authorize} from '@/routing/Authorize';
import {QUERY_KEY} from '@/wscreens/security-mode-setting/constants';
import SecurityModeSettingContent from '@/wscreens/security-mode-setting/SecurityModeSettingContent';
import SecurityModeSettingDialog from '@/wscreens/security-mode-setting/SecurityModeSettingDialog';

const dictDef = {
  securityModeSetting: {
    default: {
      default: '警備モード設定',
      [Locale.en_US]: 'Security Mode Setting',
    },
  },
  setting: {
    default: {
      default: '設定 /',
      [Locale.en_US]: 'Setting',
    },
  },
  loading: {
    default: {
      default: '読み込み中',
      [Locale.en_US]: 'Loading',
    },
  },
  countArea: {
    default: {
      default: '計測エリア',
      [Locale.en_US]: 'Count Area',
    },
  },
  confirmDelete: {
    default: {
      default: '警備モード設定を削除してよろしいですか？',
      [Locale.en_US]: 'Are you sure you want to delete the security mode setting?',
    },
  },
  confirmDescription: {
    default: {
      default: 'この操作を実行すると、設定が削除され、復元することはできません。\n本当に削除してよろしいですか？',
      [Locale.en_US]:
        'If you perform this operation, the setting will be deleted and cannot be restored. Are you sure you want to delete it?',
    },
  },
  delete: {
    default: {
      default: '設定を削除',
      [Locale.en_US]: 'Delete setting',
    },
  },
};

export const Route = createFileRoute('/_authorized/settings/security-mode')({
  component: () => (
    <Authorize featureGroup='SecurityModeSetting' feature='SecurityModeSetting'>
      <RouteComponent />
    </Authorize>
  ),
});

const logger = Logger.create('SecurityModeSettingRoute');
function RouteComponent() {
  const dict = useDict(dictDef);
  const commonDict = useCommonDict();
  const user = useLoginUser();
  const locale = useLocale();

  const useAutoSecurityMode = useMemo(() => {
    const helper = FeatureControlHelper.createInstance(user.superUser);
    const featureGroups = helper.getActivatedFeatureGroups(user.activations.map(a => a.activationGroup));
    return featureGroups.includes('AutoSecurityModeSetting');
  }, [user]);

  const [openDialog, setOpenDialog] = useState(false);
  const [running, setRunning] = useState(false);
  const [countAreaDialogOpen, setCountAreaDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteRunning, setDeleteRunning] = useState(false);

  const onOpenDialog = useCallback(() => setOpenDialog(true), []);
  const onCloseDialog = useCallback(() => setOpenDialog(false), []);
  const onOpenCountAreaDialog = useCallback(() => setCountAreaDialogOpen(true), []);
  const onCloseCountAreaDialog = useCallback(() => setCountAreaDialogOpen(false), []);
  const onOpenDeleteDialog = useCallback(() => setDeleteDialogOpen(true), []);
  const onCloseDeleteDialog = useCallback(() => setDeleteDialogOpen(false), []);

  const {
    data: currentSetting,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: [QUERY_KEY.SECURITY_MODE_SETTING_KEY, user.organizationId],
    queryFn: async () => {
      try {
        const result = await getSecurityModeSettingApi();
        return result.data;
      } catch (e) {
        if (e.response?.status === 404) {
          return undefined;
        }
      }
    },
    staleTime: 0,
    retry: 0,
    enabled: !!user.organizationId,
  });

  const {data: building} = useQuery({
    queryKey: [QUERY_KEY.SECURITY_MODE_SETTING_BUILDING_KEY, currentSetting?.buildingId],
    queryFn: async () => {
      if (!user.organization?.id || !currentSetting?.buildingId) return;
      return await fsSpaceGetById(logger, {organizationId: user.organization.id, id: currentSetting.buildingId});
    },
    enabled: !!user.organizationId || !currentSetting?.buildingId,
  });

  const queryClient = useQueryClient();
  const onOkDeleteDialog = useCallback(async () => {
    setDeleteRunning(true);
    try {
      await deleteSecurityModeSettingApi();
      // invalidate だと更新されないので、removeQueries を使う
      queryClient.removeQueries({queryKey: [QUERY_KEY.SECURITY_MODE_SETTING_KEY]});
    } finally {
      setDeleteRunning(false);
      onCloseDeleteDialog();
    }
  }, [onCloseDeleteDialog, queryClient]);

  const onOkDialog = useCallback(
    async (data: UpdateSecurityModeSettingBody | CreateSecurityModeSettingBody) => {
      setRunning(true);
      try {
        if (currentSetting) {
          await updateSecurityModeSettingApi({body: data});
        } else {
          await createSecurityModeSettingApi({body: data});
        }
        await refetch();
        onCloseDialog();
      } catch (e) {
        logger.error('Failed to update security mode setting', e);
      } finally {
        setRunning(false);
      }
    },
    [currentSetting, refetch, onCloseDialog]
  );

  const headerActions = useMemo<WHeaderActions>(
    () => ({
      others: [
        {
          label: dict.delete,
          action: () => onOpenDeleteDialog(),
          destructive: true,
        },
      ],
    }),
    [dict.delete, onOpenDeleteDialog]
  );

  const peopleCountSpacesGroupByBuildingAndFloor = useMemo(() => {
    if (!currentSetting?.peopleCountSpacesWithParents) return [];
    return currentSetting.peopleCountSpacesWithParents.reduce((acc: {[key: string]: any[]}, peopleCountSpace) => {
      const building = peopleCountSpace.building;
      const floor = peopleCountSpace.floor;
      const key = `${building?.id}-${floor?.id}`;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(peopleCountSpace);
      return acc;
    }, {});
  }, [currentSetting]);

  return (
    <>
      <Root>
        <WHeaderNavigation
          title={dict.securityModeSetting}
          navigation={dict.setting}
          actions={currentSetting ? headerActions : undefined}
        />
        {isLoading ? (
          <WLoadingComponent notTransparent message={dict.loading} />
        ) : (
          <>
            <ContentWrapper>
              <SecurityModeSettingContent
                currentSetting={currentSetting}
                useAutoSecurityMode={useAutoSecurityMode}
                onOpenDialog={onOpenDialog}
                onOpenCountAreaDialog={onOpenCountAreaDialog}
                buildingName={LocaleUtils.getLocaleName(building, locale)}
              />
            </ContentWrapper>
            <SecurityModeSettingDialog
              open={openDialog}
              onClose={onCloseDialog}
              running={running}
              onOk={onOkDialog}
              currentSetting={currentSetting}
              useAutoSecurityMode={useAutoSecurityMode}
            />
          </>
        )}
      </Root>
      {currentSetting && (
        <>
          <WDialog
            open={countAreaDialogOpen}
            onClose={onCloseCountAreaDialog}
            title={dict.countArea}
            running={false}
            cancelButtonLabel={commonDict.close}
          >
            <CountAreaDialogContent>
              <Description>{`${currentSetting.peopleCountSpacesWithParents?.length}箇所のエリア`}</Description>
              {Object.entries(peopleCountSpacesGroupByBuildingAndFloor).map(([key, peopleCountSpaces]) => {
                const building = peopleCountSpaces[0].building;
                const floor = peopleCountSpaces[0].floor;
                return (
                  <div key={key}>
                    <BuildingAndFloor>{`${building?.nameJp}/ ${floor?.nameJp}`}</BuildingAndFloor>
                    {peopleCountSpaces.map((peopleCountSpace, idx) => {
                      return <Text key={idx}>{`${peopleCountSpace.nameJp}`}</Text>;
                    })}
                  </div>
                );
              })}
            </CountAreaDialogContent>
          </WDialog>
          <WAlert
            open={deleteDialogOpen}
            onCancel={onCloseDeleteDialog}
            title={dict.confirmDelete}
            running={deleteRunning}
            destructive={true}
            onOk={onOkDeleteDialog}
            description={dict.confirmDescription}
          />
        </>
      )}
    </>
  );
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 24px;
`;

const CountAreaDialogContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  min-width: 400px;
`;

const Description = styled.div`
  white-space: pre-wrap;
  color: var(--text-neutral-secondary);
  ${WHFontCss.bodyMediumStrong};
`;

const BuildingAndFloor = styled.div`
  color: var(--text-neutral-primary);
  ${WHFontCss.bodyMedium};
`;

const Text = styled.div`
  color: var(--text-neutral-primary);
  ${WHFontCss.bodyLargeStrong};
`;
