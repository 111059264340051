import {type Auth, connectAuthEmulator, getAuth, type IdTokenResult, signInWithCustomToken} from 'firebase/auth';
import {once} from 'remeda';

import {getFirebaseApp} from './firebase';

const getFirebaseAuth = once((): Auth => {
  const auth = getAuth(getFirebaseApp());

  const emulatorHost = import.meta.env.VITE_FIREBASE_AUTH_EMULATOR_HOST;
  if (emulatorHost) {
    connectAuthEmulator(auth, emulatorHost);
  }

  return auth;
});

export async function signIntoFirebase(customToken: string): Promise<void> {
  await signInWithCustomToken(getFirebaseAuth(), customToken);
}

export const getFirebaseCurrentUser = () => getFirebaseAuth().currentUser;
export const getFirebaseCurrentUid = (): string | undefined => getFirebaseCurrentUser()?.uid;
export const getFirebaseIdToken = async (): Promise<string | undefined> => getFirebaseCurrentUser()?.getIdToken();

export const getIdTokenResult = async (): Promise<IdTokenResult | undefined> => {
  // getFirebaseAuth().currentUserが間に合わずに空の場合があるのでonAuthStateChangedで待つ
  return new Promise((resolve, reject) => {
    const unsubscribe = getFirebaseAuth().onAuthStateChanged(
      u => {
        if (u) {
          resolve(u?.getIdTokenResult());
        } else {
          resolve(undefined);
        }
        unsubscribe();
      },
      err => {
        if (err) {
          reject(err);
        }
        unsubscribe();
      }
    );
  });
};
