/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as AuthorizedImport } from './routes/_authorized'
import { Route as SendResetPasswordRouteImport } from './routes/sendResetPassword/route'
import { Route as ResetPasswordRouteImport } from './routes/resetPassword/route'
import { Route as InviteMemberRouteImport } from './routes/inviteMember/route'
import { Route as ApplicationDraftRouteImport } from './routes/application-draft/route'
import { Route as AuthorizedIndexImport } from './routes/_authorized/index'
import { Route as SsoAuthImport } from './routes/sso/auth'
import { Route as SsoCodeImport } from './routes/sso/$code'
import { Route as AuthorizedLayoutImport } from './routes/_authorized/layout'
import { Route as AuthorizedSplatImport } from './routes/_authorized/$'
import { Route as SsoSignupRouteImport } from './routes/sso/signup/route'
import { Route as AuthorizedNfcCardsRouteImport } from './routes/_authorized/nfc-cards/route'
import { Route as AuthorizedBuildingTenantServiceRouteImport } from './routes/_authorized/building-tenant-service/route'
import { Route as AuthorizedNfcCardsIndexImport } from './routes/_authorized/nfc-cards/index'
import { Route as AuthorizedLayoutIndexImport } from './routes/_authorized/layout/index'
import { Route as AuthorizedAnnouncementsIndexImport } from './routes/_authorized/announcements/index'
import { Route as AuthorizedNfcCardsNfcCardIdImport } from './routes/_authorized/nfc-cards/$nfcCardId'
import { Route as AuthorizedAnnouncementsIdImport } from './routes/_authorized/announcements/$id'
import { Route as AuthorizedSettingsSecurityModeRouteImport } from './routes/_authorized/settings/security-mode/route'
import { Route as AuthorizedLayoutFloorIdIndexImport } from './routes/_authorized/layout/$floorId/index'
import { Route as AuthorizedBuildingTenantManagementBuildingTenantServiceIndexImport } from './routes/_authorized/building-tenant-management/building-tenant-service/index'
import { Route as AuthorizedBuildingTenantManagementAnnouncementIndexImport } from './routes/_authorized/building-tenant-management/announcement/index'
import { Route as AuthorizedMobileReservationIndexImport } from './routes/_authorized/_mobile/reservation/index'
import { Route as AuthorizedBuildingTenantManagementAnnouncementCreateRouteImport } from './routes/_authorized/building-tenant-management/announcement/create/route'
import { Route as AuthorizedLayoutFloorIdLayoutCompositionIdIndexImport } from './routes/_authorized/layout/$floorId/$layoutCompositionId/index'
import { Route as AuthorizedBuildingTenantManagementAnnouncementAnnouncementIdIndexImport } from './routes/_authorized/building-tenant-management/announcement/$announcementId/index'
import { Route as AuthorizedLayoutFloorIdLayoutCompositionIdEditImport } from './routes/_authorized/layout/$floorId/$layoutCompositionId/edit'
import { Route as AuthorizedBuildingTenantManagementAnnouncementAnnouncementIdEditImport } from './routes/_authorized/building-tenant-management/announcement/$announcementId/edit'
import { Route as AuthorizedMobileFormSatisfactionCompleteImport } from './routes/_authorized/_mobile/form/satisfaction/complete'
import { Route as AuthorizedMobileFormSatisfactionSatisfactionIdImport } from './routes/_authorized/_mobile/form/satisfaction/$satisfactionId'

// Create/Update Routes

const AuthorizedRoute = AuthorizedImport.update({
  id: '/_authorized',
  getParentRoute: () => rootRoute,
} as any)

const SendResetPasswordRouteRoute = SendResetPasswordRouteImport.update({
  id: '/sendResetPassword',
  path: '/sendResetPassword',
  getParentRoute: () => rootRoute,
} as any)

const ResetPasswordRouteRoute = ResetPasswordRouteImport.update({
  id: '/resetPassword',
  path: '/resetPassword',
  getParentRoute: () => rootRoute,
} as any)

const InviteMemberRouteRoute = InviteMemberRouteImport.update({
  id: '/inviteMember',
  path: '/inviteMember',
  getParentRoute: () => rootRoute,
} as any)

const ApplicationDraftRouteRoute = ApplicationDraftRouteImport.update({
  id: '/application-draft',
  path: '/application-draft',
  getParentRoute: () => rootRoute,
} as any)

const AuthorizedIndexRoute = AuthorizedIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AuthorizedRoute,
} as any)

const SsoAuthRoute = SsoAuthImport.update({
  id: '/sso/auth',
  path: '/sso/auth',
  getParentRoute: () => rootRoute,
} as any)

const SsoCodeRoute = SsoCodeImport.update({
  id: '/sso/$code',
  path: '/sso/$code',
  getParentRoute: () => rootRoute,
} as any)

const AuthorizedLayoutRoute = AuthorizedLayoutImport.update({
  id: '/layout',
  path: '/layout',
  getParentRoute: () => AuthorizedRoute,
} as any)

const AuthorizedSplatRoute = AuthorizedSplatImport.update({
  id: '/$',
  path: '/$',
  getParentRoute: () => AuthorizedRoute,
} as any)

const SsoSignupRouteRoute = SsoSignupRouteImport.update({
  id: '/sso/signup',
  path: '/sso/signup',
  getParentRoute: () => rootRoute,
} as any)

const AuthorizedNfcCardsRouteRoute = AuthorizedNfcCardsRouteImport.update({
  id: '/nfc-cards',
  path: '/nfc-cards',
  getParentRoute: () => AuthorizedRoute,
} as any)

const AuthorizedBuildingTenantServiceRouteRoute =
  AuthorizedBuildingTenantServiceRouteImport.update({
    id: '/building-tenant-service',
    path: '/building-tenant-service',
    getParentRoute: () => AuthorizedRoute,
  } as any)

const AuthorizedNfcCardsIndexRoute = AuthorizedNfcCardsIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AuthorizedNfcCardsRouteRoute,
} as any)

const AuthorizedLayoutIndexRoute = AuthorizedLayoutIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AuthorizedLayoutRoute,
} as any)

const AuthorizedAnnouncementsIndexRoute =
  AuthorizedAnnouncementsIndexImport.update({
    id: '/announcements/',
    path: '/announcements/',
    getParentRoute: () => AuthorizedRoute,
  } as any)

const AuthorizedNfcCardsNfcCardIdRoute =
  AuthorizedNfcCardsNfcCardIdImport.update({
    id: '/$nfcCardId',
    path: '/$nfcCardId',
    getParentRoute: () => AuthorizedNfcCardsRouteRoute,
  } as any)

const AuthorizedAnnouncementsIdRoute = AuthorizedAnnouncementsIdImport.update({
  id: '/announcements/$id',
  path: '/announcements/$id',
  getParentRoute: () => AuthorizedRoute,
} as any)

const AuthorizedSettingsSecurityModeRouteRoute =
  AuthorizedSettingsSecurityModeRouteImport.update({
    id: '/settings/security-mode',
    path: '/settings/security-mode',
    getParentRoute: () => AuthorizedRoute,
  } as any)

const AuthorizedLayoutFloorIdIndexRoute =
  AuthorizedLayoutFloorIdIndexImport.update({
    id: '/$floorId/',
    path: '/$floorId/',
    getParentRoute: () => AuthorizedLayoutRoute,
  } as any)

const AuthorizedBuildingTenantManagementBuildingTenantServiceIndexRoute =
  AuthorizedBuildingTenantManagementBuildingTenantServiceIndexImport.update({
    id: '/building-tenant-management/building-tenant-service/',
    path: '/building-tenant-management/building-tenant-service/',
    getParentRoute: () => AuthorizedRoute,
  } as any)

const AuthorizedBuildingTenantManagementAnnouncementIndexRoute =
  AuthorizedBuildingTenantManagementAnnouncementIndexImport.update({
    id: '/building-tenant-management/announcement/',
    path: '/building-tenant-management/announcement/',
    getParentRoute: () => AuthorizedRoute,
  } as any)

const AuthorizedMobileReservationIndexRoute =
  AuthorizedMobileReservationIndexImport.update({
    id: '/_mobile/reservation/',
    path: '/reservation/',
    getParentRoute: () => AuthorizedRoute,
  } as any)

const AuthorizedBuildingTenantManagementAnnouncementCreateRouteRoute =
  AuthorizedBuildingTenantManagementAnnouncementCreateRouteImport.update({
    id: '/building-tenant-management/announcement/create',
    path: '/building-tenant-management/announcement/create',
    getParentRoute: () => AuthorizedRoute,
  } as any)

const AuthorizedLayoutFloorIdLayoutCompositionIdIndexRoute =
  AuthorizedLayoutFloorIdLayoutCompositionIdIndexImport.update({
    id: '/$floorId/$layoutCompositionId/',
    path: '/$floorId/$layoutCompositionId/',
    getParentRoute: () => AuthorizedLayoutRoute,
  } as any)

const AuthorizedBuildingTenantManagementAnnouncementAnnouncementIdIndexRoute =
  AuthorizedBuildingTenantManagementAnnouncementAnnouncementIdIndexImport.update(
    {
      id: '/building-tenant-management/announcement/$announcementId/',
      path: '/building-tenant-management/announcement/$announcementId/',
      getParentRoute: () => AuthorizedRoute,
    } as any,
  )

const AuthorizedLayoutFloorIdLayoutCompositionIdEditRoute =
  AuthorizedLayoutFloorIdLayoutCompositionIdEditImport.update({
    id: '/$floorId/$layoutCompositionId/edit',
    path: '/$floorId/$layoutCompositionId/edit',
    getParentRoute: () => AuthorizedLayoutRoute,
  } as any)

const AuthorizedBuildingTenantManagementAnnouncementAnnouncementIdEditRoute =
  AuthorizedBuildingTenantManagementAnnouncementAnnouncementIdEditImport.update(
    {
      id: '/building-tenant-management/announcement/$announcementId/edit',
      path: '/building-tenant-management/announcement/$announcementId/edit',
      getParentRoute: () => AuthorizedRoute,
    } as any,
  )

const AuthorizedMobileFormSatisfactionCompleteRoute =
  AuthorizedMobileFormSatisfactionCompleteImport.update({
    id: '/_mobile/form/satisfaction/complete',
    path: '/form/satisfaction/complete',
    getParentRoute: () => AuthorizedRoute,
  } as any)

const AuthorizedMobileFormSatisfactionSatisfactionIdRoute =
  AuthorizedMobileFormSatisfactionSatisfactionIdImport.update({
    id: '/_mobile/form/satisfaction/$satisfactionId',
    path: '/form/satisfaction/$satisfactionId',
    getParentRoute: () => AuthorizedRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/application-draft': {
      id: '/application-draft'
      path: '/application-draft'
      fullPath: '/application-draft'
      preLoaderRoute: typeof ApplicationDraftRouteImport
      parentRoute: typeof rootRoute
    }
    '/inviteMember': {
      id: '/inviteMember'
      path: '/inviteMember'
      fullPath: '/inviteMember'
      preLoaderRoute: typeof InviteMemberRouteImport
      parentRoute: typeof rootRoute
    }
    '/resetPassword': {
      id: '/resetPassword'
      path: '/resetPassword'
      fullPath: '/resetPassword'
      preLoaderRoute: typeof ResetPasswordRouteImport
      parentRoute: typeof rootRoute
    }
    '/sendResetPassword': {
      id: '/sendResetPassword'
      path: '/sendResetPassword'
      fullPath: '/sendResetPassword'
      preLoaderRoute: typeof SendResetPasswordRouteImport
      parentRoute: typeof rootRoute
    }
    '/_authorized': {
      id: '/_authorized'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthorizedImport
      parentRoute: typeof rootRoute
    }
    '/_authorized/building-tenant-service': {
      id: '/_authorized/building-tenant-service'
      path: '/building-tenant-service'
      fullPath: '/building-tenant-service'
      preLoaderRoute: typeof AuthorizedBuildingTenantServiceRouteImport
      parentRoute: typeof AuthorizedImport
    }
    '/_authorized/nfc-cards': {
      id: '/_authorized/nfc-cards'
      path: '/nfc-cards'
      fullPath: '/nfc-cards'
      preLoaderRoute: typeof AuthorizedNfcCardsRouteImport
      parentRoute: typeof AuthorizedImport
    }
    '/sso/signup': {
      id: '/sso/signup'
      path: '/sso/signup'
      fullPath: '/sso/signup'
      preLoaderRoute: typeof SsoSignupRouteImport
      parentRoute: typeof rootRoute
    }
    '/_authorized/$': {
      id: '/_authorized/$'
      path: '/$'
      fullPath: '/$'
      preLoaderRoute: typeof AuthorizedSplatImport
      parentRoute: typeof AuthorizedImport
    }
    '/_authorized/layout': {
      id: '/_authorized/layout'
      path: '/layout'
      fullPath: '/layout'
      preLoaderRoute: typeof AuthorizedLayoutImport
      parentRoute: typeof AuthorizedImport
    }
    '/sso/$code': {
      id: '/sso/$code'
      path: '/sso/$code'
      fullPath: '/sso/$code'
      preLoaderRoute: typeof SsoCodeImport
      parentRoute: typeof rootRoute
    }
    '/sso/auth': {
      id: '/sso/auth'
      path: '/sso/auth'
      fullPath: '/sso/auth'
      preLoaderRoute: typeof SsoAuthImport
      parentRoute: typeof rootRoute
    }
    '/_authorized/': {
      id: '/_authorized/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof AuthorizedIndexImport
      parentRoute: typeof AuthorizedImport
    }
    '/_authorized/settings/security-mode': {
      id: '/_authorized/settings/security-mode'
      path: '/settings/security-mode'
      fullPath: '/settings/security-mode'
      preLoaderRoute: typeof AuthorizedSettingsSecurityModeRouteImport
      parentRoute: typeof AuthorizedImport
    }
    '/_authorized/announcements/$id': {
      id: '/_authorized/announcements/$id'
      path: '/announcements/$id'
      fullPath: '/announcements/$id'
      preLoaderRoute: typeof AuthorizedAnnouncementsIdImport
      parentRoute: typeof AuthorizedImport
    }
    '/_authorized/nfc-cards/$nfcCardId': {
      id: '/_authorized/nfc-cards/$nfcCardId'
      path: '/$nfcCardId'
      fullPath: '/nfc-cards/$nfcCardId'
      preLoaderRoute: typeof AuthorizedNfcCardsNfcCardIdImport
      parentRoute: typeof AuthorizedNfcCardsRouteImport
    }
    '/_authorized/announcements/': {
      id: '/_authorized/announcements/'
      path: '/announcements'
      fullPath: '/announcements'
      preLoaderRoute: typeof AuthorizedAnnouncementsIndexImport
      parentRoute: typeof AuthorizedImport
    }
    '/_authorized/layout/': {
      id: '/_authorized/layout/'
      path: '/'
      fullPath: '/layout/'
      preLoaderRoute: typeof AuthorizedLayoutIndexImport
      parentRoute: typeof AuthorizedLayoutImport
    }
    '/_authorized/nfc-cards/': {
      id: '/_authorized/nfc-cards/'
      path: '/'
      fullPath: '/nfc-cards/'
      preLoaderRoute: typeof AuthorizedNfcCardsIndexImport
      parentRoute: typeof AuthorizedNfcCardsRouteImport
    }
    '/_authorized/building-tenant-management/announcement/create': {
      id: '/_authorized/building-tenant-management/announcement/create'
      path: '/building-tenant-management/announcement/create'
      fullPath: '/building-tenant-management/announcement/create'
      preLoaderRoute: typeof AuthorizedBuildingTenantManagementAnnouncementCreateRouteImport
      parentRoute: typeof AuthorizedImport
    }
    '/_authorized/_mobile/reservation/': {
      id: '/_authorized/_mobile/reservation/'
      path: '/reservation'
      fullPath: '/reservation'
      preLoaderRoute: typeof AuthorizedMobileReservationIndexImport
      parentRoute: typeof AuthorizedImport
    }
    '/_authorized/building-tenant-management/announcement/': {
      id: '/_authorized/building-tenant-management/announcement/'
      path: '/building-tenant-management/announcement'
      fullPath: '/building-tenant-management/announcement'
      preLoaderRoute: typeof AuthorizedBuildingTenantManagementAnnouncementIndexImport
      parentRoute: typeof AuthorizedImport
    }
    '/_authorized/building-tenant-management/building-tenant-service/': {
      id: '/_authorized/building-tenant-management/building-tenant-service/'
      path: '/building-tenant-management/building-tenant-service'
      fullPath: '/building-tenant-management/building-tenant-service'
      preLoaderRoute: typeof AuthorizedBuildingTenantManagementBuildingTenantServiceIndexImport
      parentRoute: typeof AuthorizedImport
    }
    '/_authorized/layout/$floorId/': {
      id: '/_authorized/layout/$floorId/'
      path: '/$floorId'
      fullPath: '/layout/$floorId'
      preLoaderRoute: typeof AuthorizedLayoutFloorIdIndexImport
      parentRoute: typeof AuthorizedLayoutImport
    }
    '/_authorized/_mobile/form/satisfaction/$satisfactionId': {
      id: '/_authorized/_mobile/form/satisfaction/$satisfactionId'
      path: '/form/satisfaction/$satisfactionId'
      fullPath: '/form/satisfaction/$satisfactionId'
      preLoaderRoute: typeof AuthorizedMobileFormSatisfactionSatisfactionIdImport
      parentRoute: typeof AuthorizedImport
    }
    '/_authorized/_mobile/form/satisfaction/complete': {
      id: '/_authorized/_mobile/form/satisfaction/complete'
      path: '/form/satisfaction/complete'
      fullPath: '/form/satisfaction/complete'
      preLoaderRoute: typeof AuthorizedMobileFormSatisfactionCompleteImport
      parentRoute: typeof AuthorizedImport
    }
    '/_authorized/building-tenant-management/announcement/$announcementId/edit': {
      id: '/_authorized/building-tenant-management/announcement/$announcementId/edit'
      path: '/building-tenant-management/announcement/$announcementId/edit'
      fullPath: '/building-tenant-management/announcement/$announcementId/edit'
      preLoaderRoute: typeof AuthorizedBuildingTenantManagementAnnouncementAnnouncementIdEditImport
      parentRoute: typeof AuthorizedImport
    }
    '/_authorized/layout/$floorId/$layoutCompositionId/edit': {
      id: '/_authorized/layout/$floorId/$layoutCompositionId/edit'
      path: '/$floorId/$layoutCompositionId/edit'
      fullPath: '/layout/$floorId/$layoutCompositionId/edit'
      preLoaderRoute: typeof AuthorizedLayoutFloorIdLayoutCompositionIdEditImport
      parentRoute: typeof AuthorizedLayoutImport
    }
    '/_authorized/building-tenant-management/announcement/$announcementId/': {
      id: '/_authorized/building-tenant-management/announcement/$announcementId/'
      path: '/building-tenant-management/announcement/$announcementId'
      fullPath: '/building-tenant-management/announcement/$announcementId'
      preLoaderRoute: typeof AuthorizedBuildingTenantManagementAnnouncementAnnouncementIdIndexImport
      parentRoute: typeof AuthorizedImport
    }
    '/_authorized/layout/$floorId/$layoutCompositionId/': {
      id: '/_authorized/layout/$floorId/$layoutCompositionId/'
      path: '/$floorId/$layoutCompositionId'
      fullPath: '/layout/$floorId/$layoutCompositionId'
      preLoaderRoute: typeof AuthorizedLayoutFloorIdLayoutCompositionIdIndexImport
      parentRoute: typeof AuthorizedLayoutImport
    }
  }
}

// Create and export the route tree

interface AuthorizedNfcCardsRouteRouteChildren {
  AuthorizedNfcCardsNfcCardIdRoute: typeof AuthorizedNfcCardsNfcCardIdRoute
  AuthorizedNfcCardsIndexRoute: typeof AuthorizedNfcCardsIndexRoute
}

const AuthorizedNfcCardsRouteRouteChildren: AuthorizedNfcCardsRouteRouteChildren =
  {
    AuthorizedNfcCardsNfcCardIdRoute: AuthorizedNfcCardsNfcCardIdRoute,
    AuthorizedNfcCardsIndexRoute: AuthorizedNfcCardsIndexRoute,
  }

const AuthorizedNfcCardsRouteRouteWithChildren =
  AuthorizedNfcCardsRouteRoute._addFileChildren(
    AuthorizedNfcCardsRouteRouteChildren,
  )

interface AuthorizedLayoutRouteChildren {
  AuthorizedLayoutIndexRoute: typeof AuthorizedLayoutIndexRoute
  AuthorizedLayoutFloorIdIndexRoute: typeof AuthorizedLayoutFloorIdIndexRoute
  AuthorizedLayoutFloorIdLayoutCompositionIdEditRoute: typeof AuthorizedLayoutFloorIdLayoutCompositionIdEditRoute
  AuthorizedLayoutFloorIdLayoutCompositionIdIndexRoute: typeof AuthorizedLayoutFloorIdLayoutCompositionIdIndexRoute
}

const AuthorizedLayoutRouteChildren: AuthorizedLayoutRouteChildren = {
  AuthorizedLayoutIndexRoute: AuthorizedLayoutIndexRoute,
  AuthorizedLayoutFloorIdIndexRoute: AuthorizedLayoutFloorIdIndexRoute,
  AuthorizedLayoutFloorIdLayoutCompositionIdEditRoute:
    AuthorizedLayoutFloorIdLayoutCompositionIdEditRoute,
  AuthorizedLayoutFloorIdLayoutCompositionIdIndexRoute:
    AuthorizedLayoutFloorIdLayoutCompositionIdIndexRoute,
}

const AuthorizedLayoutRouteWithChildren =
  AuthorizedLayoutRoute._addFileChildren(AuthorizedLayoutRouteChildren)

interface AuthorizedRouteChildren {
  AuthorizedBuildingTenantServiceRouteRoute: typeof AuthorizedBuildingTenantServiceRouteRoute
  AuthorizedNfcCardsRouteRoute: typeof AuthorizedNfcCardsRouteRouteWithChildren
  AuthorizedSplatRoute: typeof AuthorizedSplatRoute
  AuthorizedLayoutRoute: typeof AuthorizedLayoutRouteWithChildren
  AuthorizedIndexRoute: typeof AuthorizedIndexRoute
  AuthorizedSettingsSecurityModeRouteRoute: typeof AuthorizedSettingsSecurityModeRouteRoute
  AuthorizedAnnouncementsIdRoute: typeof AuthorizedAnnouncementsIdRoute
  AuthorizedAnnouncementsIndexRoute: typeof AuthorizedAnnouncementsIndexRoute
  AuthorizedBuildingTenantManagementAnnouncementCreateRouteRoute: typeof AuthorizedBuildingTenantManagementAnnouncementCreateRouteRoute
  AuthorizedMobileReservationIndexRoute: typeof AuthorizedMobileReservationIndexRoute
  AuthorizedBuildingTenantManagementAnnouncementIndexRoute: typeof AuthorizedBuildingTenantManagementAnnouncementIndexRoute
  AuthorizedBuildingTenantManagementBuildingTenantServiceIndexRoute: typeof AuthorizedBuildingTenantManagementBuildingTenantServiceIndexRoute
  AuthorizedMobileFormSatisfactionSatisfactionIdRoute: typeof AuthorizedMobileFormSatisfactionSatisfactionIdRoute
  AuthorizedMobileFormSatisfactionCompleteRoute: typeof AuthorizedMobileFormSatisfactionCompleteRoute
  AuthorizedBuildingTenantManagementAnnouncementAnnouncementIdEditRoute: typeof AuthorizedBuildingTenantManagementAnnouncementAnnouncementIdEditRoute
  AuthorizedBuildingTenantManagementAnnouncementAnnouncementIdIndexRoute: typeof AuthorizedBuildingTenantManagementAnnouncementAnnouncementIdIndexRoute
}

const AuthorizedRouteChildren: AuthorizedRouteChildren = {
  AuthorizedBuildingTenantServiceRouteRoute:
    AuthorizedBuildingTenantServiceRouteRoute,
  AuthorizedNfcCardsRouteRoute: AuthorizedNfcCardsRouteRouteWithChildren,
  AuthorizedSplatRoute: AuthorizedSplatRoute,
  AuthorizedLayoutRoute: AuthorizedLayoutRouteWithChildren,
  AuthorizedIndexRoute: AuthorizedIndexRoute,
  AuthorizedSettingsSecurityModeRouteRoute:
    AuthorizedSettingsSecurityModeRouteRoute,
  AuthorizedAnnouncementsIdRoute: AuthorizedAnnouncementsIdRoute,
  AuthorizedAnnouncementsIndexRoute: AuthorizedAnnouncementsIndexRoute,
  AuthorizedBuildingTenantManagementAnnouncementCreateRouteRoute:
    AuthorizedBuildingTenantManagementAnnouncementCreateRouteRoute,
  AuthorizedMobileReservationIndexRoute: AuthorizedMobileReservationIndexRoute,
  AuthorizedBuildingTenantManagementAnnouncementIndexRoute:
    AuthorizedBuildingTenantManagementAnnouncementIndexRoute,
  AuthorizedBuildingTenantManagementBuildingTenantServiceIndexRoute:
    AuthorizedBuildingTenantManagementBuildingTenantServiceIndexRoute,
  AuthorizedMobileFormSatisfactionSatisfactionIdRoute:
    AuthorizedMobileFormSatisfactionSatisfactionIdRoute,
  AuthorizedMobileFormSatisfactionCompleteRoute:
    AuthorizedMobileFormSatisfactionCompleteRoute,
  AuthorizedBuildingTenantManagementAnnouncementAnnouncementIdEditRoute:
    AuthorizedBuildingTenantManagementAnnouncementAnnouncementIdEditRoute,
  AuthorizedBuildingTenantManagementAnnouncementAnnouncementIdIndexRoute:
    AuthorizedBuildingTenantManagementAnnouncementAnnouncementIdIndexRoute,
}

const AuthorizedRouteWithChildren = AuthorizedRoute._addFileChildren(
  AuthorizedRouteChildren,
)

export interface FileRoutesByFullPath {
  '/application-draft': typeof ApplicationDraftRouteRoute
  '/inviteMember': typeof InviteMemberRouteRoute
  '/resetPassword': typeof ResetPasswordRouteRoute
  '/sendResetPassword': typeof SendResetPasswordRouteRoute
  '': typeof AuthorizedRouteWithChildren
  '/building-tenant-service': typeof AuthorizedBuildingTenantServiceRouteRoute
  '/nfc-cards': typeof AuthorizedNfcCardsRouteRouteWithChildren
  '/sso/signup': typeof SsoSignupRouteRoute
  '/$': typeof AuthorizedSplatRoute
  '/layout': typeof AuthorizedLayoutRouteWithChildren
  '/sso/$code': typeof SsoCodeRoute
  '/sso/auth': typeof SsoAuthRoute
  '/': typeof AuthorizedIndexRoute
  '/settings/security-mode': typeof AuthorizedSettingsSecurityModeRouteRoute
  '/announcements/$id': typeof AuthorizedAnnouncementsIdRoute
  '/nfc-cards/$nfcCardId': typeof AuthorizedNfcCardsNfcCardIdRoute
  '/announcements': typeof AuthorizedAnnouncementsIndexRoute
  '/layout/': typeof AuthorizedLayoutIndexRoute
  '/nfc-cards/': typeof AuthorizedNfcCardsIndexRoute
  '/building-tenant-management/announcement/create': typeof AuthorizedBuildingTenantManagementAnnouncementCreateRouteRoute
  '/reservation': typeof AuthorizedMobileReservationIndexRoute
  '/building-tenant-management/announcement': typeof AuthorizedBuildingTenantManagementAnnouncementIndexRoute
  '/building-tenant-management/building-tenant-service': typeof AuthorizedBuildingTenantManagementBuildingTenantServiceIndexRoute
  '/layout/$floorId': typeof AuthorizedLayoutFloorIdIndexRoute
  '/form/satisfaction/$satisfactionId': typeof AuthorizedMobileFormSatisfactionSatisfactionIdRoute
  '/form/satisfaction/complete': typeof AuthorizedMobileFormSatisfactionCompleteRoute
  '/building-tenant-management/announcement/$announcementId/edit': typeof AuthorizedBuildingTenantManagementAnnouncementAnnouncementIdEditRoute
  '/layout/$floorId/$layoutCompositionId/edit': typeof AuthorizedLayoutFloorIdLayoutCompositionIdEditRoute
  '/building-tenant-management/announcement/$announcementId': typeof AuthorizedBuildingTenantManagementAnnouncementAnnouncementIdIndexRoute
  '/layout/$floorId/$layoutCompositionId': typeof AuthorizedLayoutFloorIdLayoutCompositionIdIndexRoute
}

export interface FileRoutesByTo {
  '/application-draft': typeof ApplicationDraftRouteRoute
  '/inviteMember': typeof InviteMemberRouteRoute
  '/resetPassword': typeof ResetPasswordRouteRoute
  '/sendResetPassword': typeof SendResetPasswordRouteRoute
  '/building-tenant-service': typeof AuthorizedBuildingTenantServiceRouteRoute
  '/sso/signup': typeof SsoSignupRouteRoute
  '/$': typeof AuthorizedSplatRoute
  '/sso/$code': typeof SsoCodeRoute
  '/sso/auth': typeof SsoAuthRoute
  '/': typeof AuthorizedIndexRoute
  '/settings/security-mode': typeof AuthorizedSettingsSecurityModeRouteRoute
  '/announcements/$id': typeof AuthorizedAnnouncementsIdRoute
  '/nfc-cards/$nfcCardId': typeof AuthorizedNfcCardsNfcCardIdRoute
  '/announcements': typeof AuthorizedAnnouncementsIndexRoute
  '/layout': typeof AuthorizedLayoutIndexRoute
  '/nfc-cards': typeof AuthorizedNfcCardsIndexRoute
  '/building-tenant-management/announcement/create': typeof AuthorizedBuildingTenantManagementAnnouncementCreateRouteRoute
  '/reservation': typeof AuthorizedMobileReservationIndexRoute
  '/building-tenant-management/announcement': typeof AuthorizedBuildingTenantManagementAnnouncementIndexRoute
  '/building-tenant-management/building-tenant-service': typeof AuthorizedBuildingTenantManagementBuildingTenantServiceIndexRoute
  '/layout/$floorId': typeof AuthorizedLayoutFloorIdIndexRoute
  '/form/satisfaction/$satisfactionId': typeof AuthorizedMobileFormSatisfactionSatisfactionIdRoute
  '/form/satisfaction/complete': typeof AuthorizedMobileFormSatisfactionCompleteRoute
  '/building-tenant-management/announcement/$announcementId/edit': typeof AuthorizedBuildingTenantManagementAnnouncementAnnouncementIdEditRoute
  '/layout/$floorId/$layoutCompositionId/edit': typeof AuthorizedLayoutFloorIdLayoutCompositionIdEditRoute
  '/building-tenant-management/announcement/$announcementId': typeof AuthorizedBuildingTenantManagementAnnouncementAnnouncementIdIndexRoute
  '/layout/$floorId/$layoutCompositionId': typeof AuthorizedLayoutFloorIdLayoutCompositionIdIndexRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/application-draft': typeof ApplicationDraftRouteRoute
  '/inviteMember': typeof InviteMemberRouteRoute
  '/resetPassword': typeof ResetPasswordRouteRoute
  '/sendResetPassword': typeof SendResetPasswordRouteRoute
  '/_authorized': typeof AuthorizedRouteWithChildren
  '/_authorized/building-tenant-service': typeof AuthorizedBuildingTenantServiceRouteRoute
  '/_authorized/nfc-cards': typeof AuthorizedNfcCardsRouteRouteWithChildren
  '/sso/signup': typeof SsoSignupRouteRoute
  '/_authorized/$': typeof AuthorizedSplatRoute
  '/_authorized/layout': typeof AuthorizedLayoutRouteWithChildren
  '/sso/$code': typeof SsoCodeRoute
  '/sso/auth': typeof SsoAuthRoute
  '/_authorized/': typeof AuthorizedIndexRoute
  '/_authorized/settings/security-mode': typeof AuthorizedSettingsSecurityModeRouteRoute
  '/_authorized/announcements/$id': typeof AuthorizedAnnouncementsIdRoute
  '/_authorized/nfc-cards/$nfcCardId': typeof AuthorizedNfcCardsNfcCardIdRoute
  '/_authorized/announcements/': typeof AuthorizedAnnouncementsIndexRoute
  '/_authorized/layout/': typeof AuthorizedLayoutIndexRoute
  '/_authorized/nfc-cards/': typeof AuthorizedNfcCardsIndexRoute
  '/_authorized/building-tenant-management/announcement/create': typeof AuthorizedBuildingTenantManagementAnnouncementCreateRouteRoute
  '/_authorized/_mobile/reservation/': typeof AuthorizedMobileReservationIndexRoute
  '/_authorized/building-tenant-management/announcement/': typeof AuthorizedBuildingTenantManagementAnnouncementIndexRoute
  '/_authorized/building-tenant-management/building-tenant-service/': typeof AuthorizedBuildingTenantManagementBuildingTenantServiceIndexRoute
  '/_authorized/layout/$floorId/': typeof AuthorizedLayoutFloorIdIndexRoute
  '/_authorized/_mobile/form/satisfaction/$satisfactionId': typeof AuthorizedMobileFormSatisfactionSatisfactionIdRoute
  '/_authorized/_mobile/form/satisfaction/complete': typeof AuthorizedMobileFormSatisfactionCompleteRoute
  '/_authorized/building-tenant-management/announcement/$announcementId/edit': typeof AuthorizedBuildingTenantManagementAnnouncementAnnouncementIdEditRoute
  '/_authorized/layout/$floorId/$layoutCompositionId/edit': typeof AuthorizedLayoutFloorIdLayoutCompositionIdEditRoute
  '/_authorized/building-tenant-management/announcement/$announcementId/': typeof AuthorizedBuildingTenantManagementAnnouncementAnnouncementIdIndexRoute
  '/_authorized/layout/$floorId/$layoutCompositionId/': typeof AuthorizedLayoutFloorIdLayoutCompositionIdIndexRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/application-draft'
    | '/inviteMember'
    | '/resetPassword'
    | '/sendResetPassword'
    | ''
    | '/building-tenant-service'
    | '/nfc-cards'
    | '/sso/signup'
    | '/$'
    | '/layout'
    | '/sso/$code'
    | '/sso/auth'
    | '/'
    | '/settings/security-mode'
    | '/announcements/$id'
    | '/nfc-cards/$nfcCardId'
    | '/announcements'
    | '/layout/'
    | '/nfc-cards/'
    | '/building-tenant-management/announcement/create'
    | '/reservation'
    | '/building-tenant-management/announcement'
    | '/building-tenant-management/building-tenant-service'
    | '/layout/$floorId'
    | '/form/satisfaction/$satisfactionId'
    | '/form/satisfaction/complete'
    | '/building-tenant-management/announcement/$announcementId/edit'
    | '/layout/$floorId/$layoutCompositionId/edit'
    | '/building-tenant-management/announcement/$announcementId'
    | '/layout/$floorId/$layoutCompositionId'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/application-draft'
    | '/inviteMember'
    | '/resetPassword'
    | '/sendResetPassword'
    | '/building-tenant-service'
    | '/sso/signup'
    | '/$'
    | '/sso/$code'
    | '/sso/auth'
    | '/'
    | '/settings/security-mode'
    | '/announcements/$id'
    | '/nfc-cards/$nfcCardId'
    | '/announcements'
    | '/layout'
    | '/nfc-cards'
    | '/building-tenant-management/announcement/create'
    | '/reservation'
    | '/building-tenant-management/announcement'
    | '/building-tenant-management/building-tenant-service'
    | '/layout/$floorId'
    | '/form/satisfaction/$satisfactionId'
    | '/form/satisfaction/complete'
    | '/building-tenant-management/announcement/$announcementId/edit'
    | '/layout/$floorId/$layoutCompositionId/edit'
    | '/building-tenant-management/announcement/$announcementId'
    | '/layout/$floorId/$layoutCompositionId'
  id:
    | '__root__'
    | '/application-draft'
    | '/inviteMember'
    | '/resetPassword'
    | '/sendResetPassword'
    | '/_authorized'
    | '/_authorized/building-tenant-service'
    | '/_authorized/nfc-cards'
    | '/sso/signup'
    | '/_authorized/$'
    | '/_authorized/layout'
    | '/sso/$code'
    | '/sso/auth'
    | '/_authorized/'
    | '/_authorized/settings/security-mode'
    | '/_authorized/announcements/$id'
    | '/_authorized/nfc-cards/$nfcCardId'
    | '/_authorized/announcements/'
    | '/_authorized/layout/'
    | '/_authorized/nfc-cards/'
    | '/_authorized/building-tenant-management/announcement/create'
    | '/_authorized/_mobile/reservation/'
    | '/_authorized/building-tenant-management/announcement/'
    | '/_authorized/building-tenant-management/building-tenant-service/'
    | '/_authorized/layout/$floorId/'
    | '/_authorized/_mobile/form/satisfaction/$satisfactionId'
    | '/_authorized/_mobile/form/satisfaction/complete'
    | '/_authorized/building-tenant-management/announcement/$announcementId/edit'
    | '/_authorized/layout/$floorId/$layoutCompositionId/edit'
    | '/_authorized/building-tenant-management/announcement/$announcementId/'
    | '/_authorized/layout/$floorId/$layoutCompositionId/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  ApplicationDraftRouteRoute: typeof ApplicationDraftRouteRoute
  InviteMemberRouteRoute: typeof InviteMemberRouteRoute
  ResetPasswordRouteRoute: typeof ResetPasswordRouteRoute
  SendResetPasswordRouteRoute: typeof SendResetPasswordRouteRoute
  AuthorizedRoute: typeof AuthorizedRouteWithChildren
  SsoSignupRouteRoute: typeof SsoSignupRouteRoute
  SsoCodeRoute: typeof SsoCodeRoute
  SsoAuthRoute: typeof SsoAuthRoute
}

const rootRouteChildren: RootRouteChildren = {
  ApplicationDraftRouteRoute: ApplicationDraftRouteRoute,
  InviteMemberRouteRoute: InviteMemberRouteRoute,
  ResetPasswordRouteRoute: ResetPasswordRouteRoute,
  SendResetPasswordRouteRoute: SendResetPasswordRouteRoute,
  AuthorizedRoute: AuthorizedRouteWithChildren,
  SsoSignupRouteRoute: SsoSignupRouteRoute,
  SsoCodeRoute: SsoCodeRoute,
  SsoAuthRoute: SsoAuthRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/application-draft",
        "/inviteMember",
        "/resetPassword",
        "/sendResetPassword",
        "/_authorized",
        "/sso/signup",
        "/sso/$code",
        "/sso/auth"
      ]
    },
    "/application-draft": {
      "filePath": "application-draft/route.tsx"
    },
    "/inviteMember": {
      "filePath": "inviteMember/route.tsx"
    },
    "/resetPassword": {
      "filePath": "resetPassword/route.tsx"
    },
    "/sendResetPassword": {
      "filePath": "sendResetPassword/route.tsx"
    },
    "/_authorized": {
      "filePath": "_authorized.tsx",
      "children": [
        "/_authorized/building-tenant-service",
        "/_authorized/nfc-cards",
        "/_authorized/$",
        "/_authorized/layout",
        "/_authorized/",
        "/_authorized/settings/security-mode",
        "/_authorized/announcements/$id",
        "/_authorized/announcements/",
        "/_authorized/building-tenant-management/announcement/create",
        "/_authorized/_mobile/reservation/",
        "/_authorized/building-tenant-management/announcement/",
        "/_authorized/building-tenant-management/building-tenant-service/",
        "/_authorized/_mobile/form/satisfaction/$satisfactionId",
        "/_authorized/_mobile/form/satisfaction/complete",
        "/_authorized/building-tenant-management/announcement/$announcementId/edit",
        "/_authorized/building-tenant-management/announcement/$announcementId/"
      ]
    },
    "/_authorized/building-tenant-service": {
      "filePath": "_authorized/building-tenant-service/route.tsx",
      "parent": "/_authorized"
    },
    "/_authorized/nfc-cards": {
      "filePath": "_authorized/nfc-cards/route.tsx",
      "parent": "/_authorized",
      "children": [
        "/_authorized/nfc-cards/$nfcCardId",
        "/_authorized/nfc-cards/"
      ]
    },
    "/sso/signup": {
      "filePath": "sso/signup/route.tsx"
    },
    "/_authorized/$": {
      "filePath": "_authorized/$.tsx",
      "parent": "/_authorized"
    },
    "/_authorized/layout": {
      "filePath": "_authorized/layout.tsx",
      "parent": "/_authorized",
      "children": [
        "/_authorized/layout/",
        "/_authorized/layout/$floorId/",
        "/_authorized/layout/$floorId/$layoutCompositionId/edit",
        "/_authorized/layout/$floorId/$layoutCompositionId/"
      ]
    },
    "/sso/$code": {
      "filePath": "sso/$code.tsx"
    },
    "/sso/auth": {
      "filePath": "sso/auth.tsx"
    },
    "/_authorized/": {
      "filePath": "_authorized/index.tsx",
      "parent": "/_authorized"
    },
    "/_authorized/settings/security-mode": {
      "filePath": "_authorized/settings/security-mode/route.tsx",
      "parent": "/_authorized"
    },
    "/_authorized/announcements/$id": {
      "filePath": "_authorized/announcements/$id.tsx",
      "parent": "/_authorized"
    },
    "/_authorized/nfc-cards/$nfcCardId": {
      "filePath": "_authorized/nfc-cards/$nfcCardId.tsx",
      "parent": "/_authorized/nfc-cards"
    },
    "/_authorized/announcements/": {
      "filePath": "_authorized/announcements/index.tsx",
      "parent": "/_authorized"
    },
    "/_authorized/layout/": {
      "filePath": "_authorized/layout/index.tsx",
      "parent": "/_authorized/layout"
    },
    "/_authorized/nfc-cards/": {
      "filePath": "_authorized/nfc-cards/index.tsx",
      "parent": "/_authorized/nfc-cards"
    },
    "/_authorized/building-tenant-management/announcement/create": {
      "filePath": "_authorized/building-tenant-management/announcement/create/route.tsx",
      "parent": "/_authorized"
    },
    "/_authorized/_mobile/reservation/": {
      "filePath": "_authorized/_mobile/reservation/index.tsx",
      "parent": "/_authorized"
    },
    "/_authorized/building-tenant-management/announcement/": {
      "filePath": "_authorized/building-tenant-management/announcement/index.tsx",
      "parent": "/_authorized"
    },
    "/_authorized/building-tenant-management/building-tenant-service/": {
      "filePath": "_authorized/building-tenant-management/building-tenant-service/index.tsx",
      "parent": "/_authorized"
    },
    "/_authorized/layout/$floorId/": {
      "filePath": "_authorized/layout/$floorId/index.tsx",
      "parent": "/_authorized/layout"
    },
    "/_authorized/_mobile/form/satisfaction/$satisfactionId": {
      "filePath": "_authorized/_mobile/form/satisfaction/$satisfactionId.tsx",
      "parent": "/_authorized"
    },
    "/_authorized/_mobile/form/satisfaction/complete": {
      "filePath": "_authorized/_mobile/form/satisfaction/complete.tsx",
      "parent": "/_authorized"
    },
    "/_authorized/building-tenant-management/announcement/$announcementId/edit": {
      "filePath": "_authorized/building-tenant-management/announcement/$announcementId/edit.tsx",
      "parent": "/_authorized"
    },
    "/_authorized/layout/$floorId/$layoutCompositionId/edit": {
      "filePath": "_authorized/layout/$floorId/$layoutCompositionId/edit.tsx",
      "parent": "/_authorized/layout"
    },
    "/_authorized/building-tenant-management/announcement/$announcementId/": {
      "filePath": "_authorized/building-tenant-management/announcement/$announcementId/index.tsx",
      "parent": "/_authorized"
    },
    "/_authorized/layout/$floorId/$layoutCompositionId/": {
      "filePath": "_authorized/layout/$floorId/$layoutCompositionId/index.tsx",
      "parent": "/_authorized/layout"
    }
  }
}
ROUTE_MANIFEST_END */
