import {createFileRoute, useLocation, useNavigate} from '@tanstack/react-router';
import {useEffect} from 'react';

import {ApiIdgateBridge} from '@/api/account/idgate-bridge/apiIdgateBridge';
import {loginHHCrossApi} from '@/api-call/workhub-core/loginHHCrossApi';
import {signUpHHCrossApi} from '@/api-call/workhub-core/signUpHHCrossApi';
import {SsoIdProviders, SsoKeys, SsoOperations} from '@/common/constant/cookie';
import useDict from '@/common/hooks/useDict';
import Logger from '@/common/logger/logger';
import {Locale} from '@/common/redux/state-types/localeStateType';
import {getCookieValueByKey, removeCookieValueByKey} from '@/common/storage/cookie';
import {LoginPersonaLocalStorage} from '@/common/storage/localStorage';
import WLoadingComponent from '@/components/figma/others/stepper/WLoadingComponent';

export const Route = createFileRoute('/sso/auth')({
  component: RouteComponent,
});

const dictDef = {
  loading: {
    default: {
      default: '認証中です...',
      [Locale.en_US]: 'Loading...',
    },
  },
};

const logger = Logger.create('SSOAuth');

function RouteComponent() {
  const dict = useDict(dictDef);
  const {searchStr} = useLocation();
  const navigate = useNavigate();

  const loginForHhCross = async (state: string, code: string) => {
    const res = await loginHHCrossApi(
      {body: {state, code, redirectUri: import.meta.env.VITE_SSO_REDIRECT_URL}},
      {region: 'asia'}
    );
    LoginPersonaLocalStorage.setOnlyAccessToken({accessToken: res.data.accessToken});
    removeCookieValueByKey(SsoKeys.SsoIdProvider);
    removeCookieValueByKey(SsoKeys.Operation);
    removeCookieValueByKey(SsoKeys.InvitationId);
    navigate({to: '/'});
  };

  const signUpForHhCross = async (state: string, code: string) => {
    const invitationId = getCookieValueByKey(SsoKeys.InvitationId);
    if (!invitationId) {
      logger.error('SSO login is failed. invitationId is not found', {
        name: 'SSO Error',
        message: `SSO login is failed. invitationId is not found`,
      });
      return;
    }
    const res = await signUpHHCrossApi({body: {state, code, invitationId}}, {region: 'asia'});
    LoginPersonaLocalStorage.setOnlyAccessToken({accessToken: res.data.accessToken});
    removeCookieValueByKey(SsoKeys.SsoIdProvider);
    removeCookieValueByKey(SsoKeys.Operation);
    navigate({to: `/sso/signup?id=${invitationId}&success=true`});
  };

  useEffect(() => {
    (async () => {
      try {
        const code = new URLSearchParams(searchStr).get(SsoKeys.Code);
        if (!code) {
          logger.error('SSO login is failed. code is not found', {
            name: 'SSO Error',
            message: `SSO login is failed. code is not found`,
          });
          return;
        }
        const ssoIdProvider = getCookieValueByKey(SsoKeys.SsoIdProvider);

        if (ssoIdProvider === SsoIdProviders.HhCross) {
          const state = new URLSearchParams(searchStr).get(SsoKeys.State);
          if (!state) {
            logger.error('SSO login is failed. state is not found', {
              name: 'SSO Error',
              message: `SSO login is failed. state is not found`,
            });
            return;
          }
          const operation = getCookieValueByKey(SsoKeys.Operation);
          if (!operation) {
            logger.error('SSO login is failed. operation is not found', {
              name: 'SSO Error',
              message: `SSO login is failed. operation is not found`,
            });
            return;
          }
          if (operation === SsoOperations.Signin) {
            await loginForHhCross(state, code);
          } else {
            await signUpForHhCross(state, code);
          }
          return;
        }

        const {
          tokens: {access_token: accessToken},
        } = await ApiIdgateBridge.auth({authenticationCode: code});

        // 一旦、SSO経由では、access token だけを local storage に保存する。
        LoginPersonaLocalStorage.setOnlyAccessToken({accessToken});

        // その後history push で / に遷移し、いつものログイン処理を実行することで
        // local storage に organizationId, personaId, refreshToken etc... を保存する流れになる
        navigate({to: '/'});
      } catch (e) {
        logger.error('failed to sso auth', e);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <WLoadingComponent message={dict.loading} notTransparent />;
}
