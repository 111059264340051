import {useLocale} from './useLocale';
import {Locale} from '../redux/state-types/localeStateType';
import {NameObj} from '../types/locale-types';
import {LocaleUtils} from '../utils/localeUtils';

const useLocaleName = <T extends NameObj>(obj: T) => {
  const locale = useLocale();
  if (locale === Locale.ja_JP) {
    const jp = LocaleUtils.getJpName(obj);
    return jp ? jp : LocaleUtils.getEnName(obj);
  } else {
    const en = LocaleUtils.getEnName(obj);
    return en ? en : LocaleUtils.getJpName(obj);
  }
};

export default useLocaleName;
