import {createFileRoute} from '@tanstack/react-router';
import {lazy, useEffect, useState} from 'react';
import {isMobileOnly} from 'react-device-detect';

import {useLoginUser} from '@/common/hooks/useLoginUser';
import userSlice from '@/common/redux/slices/userSlice';
import {useAsyncDispatch} from '@/common/redux/store/store';
import {CookieKey, getCookieValueByKey, removeCookieValueByKey} from '@/common/storage/cookie';
import {LoginPersonaLocalStorage} from '@/common/storage/localStorage';
import MobileWelcomeScreen from '@/mobile/screens/welcome/WelcomeScreen';
import {TokenValidityChecker} from '@/routing/TokenValidityChecker';
import {SecuritySettingService} from '@/v2_service/common-settings/SecuritySettingService';
import WelcomeScreen from '@/wscreens/welcome/WelcomeScreen';

/**
 * 認証済みの状態で表示できるページ共通のレイアウト
 */
export const Route = createFileRoute('/_authorized')({
  component: RouteComponent,
});

const Layout = lazy(() => import('@/components/layouts/Layout'));
const MobileLayout = lazy(() => import('@/mobile/components/layouts/Layout'));
const Auth = lazy(() => import('@/routing/Auth'));

const WorkhubBasePath = import.meta.env.VITE_WORKHUB_PATH as string;

function RouteComponent() {
  const user = useLoginUser();
  const dispatch = useAsyncDispatch();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    const t = new URLSearchParams(window.location.search).get('t');
    const tokenFromCookie = getCookieValueByKey(CookieKey.accessToken);
    const accessToken = t ?? tokenFromCookie ?? LoginPersonaLocalStorage.get().accessToken;
    if (accessToken) {
      dispatch(userSlice.actions.set({accessToken}));
    }
    if (tokenFromCookie) {
      removeCookieValueByKey(CookieKey.accessToken);
    }
    setInitialized(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (
    WorkhubBasePath.indexOf(window.location.host) === -1 && // 指定のホストでない
    window.location.host.indexOf(`workhub-dev-x--`) === -1 && // previewでない(開発用)
    window.location.host.indexOf(`workhub-stg--`) === -1 && // previewでない(STG用)
    window.location.host.indexOf(`workhub-stg01--`) === -1 && // previewでない(staging01用)
    window.location.host.indexOf(`workhub-production--`) === -1 && // previewでない(本番用)
    window.location.host.indexOf('localhost') === -1 && // localhostでない(開発用)
    !SecuritySettingService.validateIpv4({ipAddress: window.location.hostname}) // ipアドレスでない(開発用)
  ) {
    window.location.replace(WorkhubBasePath + window.location.pathname);
    return <></>;
  }

  return (
    <>
      {isMobileOnly ? (
        user && user.personaId ? (
          <MobileLayout />
        ) : user.accessToken ? (
          <Auth accessToken={user.accessToken} />
        ) : initialized ? (
          <MobileWelcomeScreen />
        ) : (
          <></>
        )
      ) : user && user.personaId ? (
        <Layout />
      ) : user.accessToken ? (
        <Auth accessToken={user.accessToken} />
      ) : initialized ? (
        <WelcomeScreen />
      ) : (
        <></>
      )}
      <TokenValidityChecker />
    </>
  );
}
