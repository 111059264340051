import {createAuthenticatedClient} from '@/api/createClient';

/**
 * cloud runのcore APIクライアントとなるaxiosインスタンスを取得する
 * IDトークンは利用するところで取得して渡す
 *
 * TODO: @/api/core に移動して api-call とまとめる
 */
export const getCoreAxios = (opts?: {region?: 'default' | 'asia'}) => {
  return createAuthenticatedClient('core', new URL(getBaseUrl(opts?.region)));
};

const getBaseUrl = (region?: 'default' | 'asia') => {
  if (region === 'asia') {
    return import.meta.env.VITE_CORE_ASIA_API_URL;
  }
  return import.meta.env.VITE_CORE_DEFAULT_API_URL;
};
