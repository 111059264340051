/**
 * 環境情報を管理するファイル
 * サーバー側とは情報が異なるためserviceのenvを持ってくるのは禁止
 * 各クライアント間では共有してOK
 */
import Logger, {LogLevel} from '../logger/logger';

const initLogger = () => {
  if (import.meta.env.VITE_LOG_LEVEL) {
    const logLevel = LogLevel[import.meta.env.VITE_LOG_LEVEL];
    if (!logLevel) {
      throw new Error('log level must be one of LogLevel.');
    }
    Logger.setLogLevel(logLevel as unknown as LogLevel);
  } else {
    throw new Error('log level must be specified.');
  }
};

const checkEnv = () => {
  if (!Env.getApiHost()) {
    throw new Error('api host required.');
  }
};

export default class Env {
  // TODO 環境情報全部.envに移動
  public static init = () => {
    checkEnv();
    initLogger();
  };

  public static getGCPProjectId = () => import.meta.env.VITE_FIREBASE_PROJECT_ID as string;
  public static getApiHost = () => import.meta.env.VITE_API_HOST as string;

  /**
   * 公開ドキュメント用バケットURL
   */
  public static getDocsStorageBucketUrl = () => 'gs://' + import.meta.env.VITE_FIREBASE_DOCS_STORAGE_BUCKET;

  /**
   * 公開ドキュメントのホスト名。バケット名と同一。
   */
  public static getDocsStorageHost = () => 'https://' + import.meta.env.VITE_FIREBASE_DOCS_STORAGE_BUCKET;
}
