import {combineReducers} from '@reduxjs/toolkit';
import {Dispatch} from 'redux';

import {wowtalkIntegrationSlice} from '@/common/redux/slices/wowtalkIntegrationSlice';

import {ObjectUtil} from '../../utils/objectUtil';
import {screensClearActions} from '../screens/screensClearActions';
import {screensReducer} from '../screens/screensReducer';
import announcementSlice from '../slices/announcementSlice';
import confirmationSlice from '../slices/confirmationSlice';
import {experimentalFeaturesSlice} from '../slices/experimentalFeaturesSlice';
import labelSlice from '../slices/labelSlice';
import localeSlice from '../slices/localeSlice';
import organizationSlice from '../slices/organizationSlice';
import userGroupSlice from '../slices/userGroupSlice';
import userSlice from '../slices/userSlice';

const reducers = {
  user: userSlice.reducer,
  organization: organizationSlice.reducer,
  userGroups: userGroupSlice.reducer,
  locale: localeSlice.reducer,
  announcements: announcementSlice.reducer,
  screens: screensReducer,
  confirmations: confirmationSlice.reducer,
  label: labelSlice.reducer,
  wowtalkSetting: wowtalkIntegrationSlice.reducer,
  experimentalFeatures: experimentalFeaturesSlice.reducer,
};
export const rootReducer = combineReducers(reducers);

// 漏れそうなのでここで定義する (useDispatchはここじゃ呼べないので呼び出し元で定義)
// 画面別のstateはclear関数の階層が違うため別でやる
const stateClearFunctions: {[K in keyof Omit<typeof reducers, 'screens'>]: any} = {
  user: userSlice.actions.clear,
  organization: organizationSlice.actions.clear,
  userGroups: userGroupSlice.actions.clear,
  locale: localeSlice.actions.clear,
  announcements: announcementSlice.actions.clear,
  confirmations: confirmationSlice.actions.clear,
  label: labelSlice.actions.clear,
  wowtalkSetting: wowtalkIntegrationSlice.actions.clear,
  experimentalFeatures: experimentalFeaturesSlice.actions.clear,
};
export const clearAllState = (dispatch: Dispatch<any>) => {
  for (const f of Object.values(stateClearFunctions)) {
    dispatch(f());
  }
  for (const clearAction of ObjectUtil.values(screensClearActions)) {
    if (clearAction) {
      dispatch(clearAction());
    }
  }
};
