import {OperationLogEventTriggers} from '@bitkey-service/v2_core-types/lib/enum/operation-logs/operationLogEventTypes';
import type {AxiosRequestConfig} from 'axios';

import {createAuthenticatedClient} from '@/api/createClient';
import {axiosCatch} from '@/common/error/axiosError';
import {getStackTrace} from '@/common/error/stacktrace';

/**
 * 操作ログを保存するためのパラメータ
 */
export const csvLogOption = {trigger: OperationLogEventTriggers.Csv, disable: false};
export const manualLogOption = {trigger: OperationLogEventTriggers.Manual, disable: false};
export const coreLogOption = (csv: boolean) => {
  return csv ? csvLogOption : manualLogOption;
};

const client = createAuthenticatedClient('core-legacy', new URL('./workhubCore/v2', import.meta.env.VITE_API_HOST));

export class ApiWorkhubCore {
  static post = async <Response, Request>(
    path: string,
    data: Request,
    config?: AxiosRequestConfig
  ): Promise<Response> => {
    const res = await client.post(path, data, config).catch(axiosCatch(getStackTrace()));
    return res.data;
  };

  static put = async <Response, Request>(
    path: string,
    data: Request,
    config?: AxiosRequestConfig
  ): Promise<Response> => {
    const res = await client.put(path, data, config).catch(axiosCatch(getStackTrace()));
    return res.data;
  };

  static patch = async <Response, Request>(
    path: string,
    data: Request,
    config?: AxiosRequestConfig
  ): Promise<Response> => {
    const res = await client.patch(path, data, config).catch(axiosCatch(getStackTrace()));
    return res.data;
  };

  static get = async <Response>(path: string, config?: AxiosRequestConfig): Promise<Response> => {
    const res = await client.get(path, config).catch(axiosCatch(getStackTrace()));
    return res.data;
  };

  static delete = async <Response>(path: string, config?: AxiosRequestConfig): Promise<Response> => {
    const res = await client.delete(path, config).catch(axiosCatch(getStackTrace()));
    return res.data;
  };
}
