import {datadogLogs, LogsInitConfiguration} from '@datadog/browser-logs';
import {datadogRum, RumInitConfiguration} from '@datadog/browser-rum';

import {getCurrentVersion} from '@/common/utils/version';

type PropagatorType = 'datadog' | 'b3' | 'b3multi' | 'tracecontext';

export class Datadog {
  private static initialized = false;
  private constructor() {
    // disable constructor to prevend unnecessary initialization.
  }

  public static startMonitoring() {
    if (!this.initialized) {
      const version = getCurrentVersion();
      const {env, service, clientToken, site, applicationId, enabled, allowedTracingUrls} = readVariables();
      if (enabled) {
        datadogRum.init({
          env,
          service,
          clientToken,
          site: site as RumInitConfiguration['site'],
          applicationId,
          allowedTracingUrls,
          // Specify a version number to identify the deployed version of your application in Datadog
          version: version,
          // 問い合わせ調査として利用しているので、意図的に、100 にしておく。
          sessionSampleRate: 100,
          // 問い合わせ調査として利用しているので、意図的に、100 にしておく。
          traceSampleRate: 100,
          // session replay はお金がかかるので、本番環境のみ sampling する
          sessionReplaySampleRate: env === 'production' ? 20 : 100,
          defaultPrivacyLevel: env === 'production' ? 'mask' : 'allow',
          trackResources: true,
          trackLongTasks: true,
          // Action を計測したり、ヒートマップを見たりとかで利用するので、有効化しておく。
          // TODO: プライバシー対策を行う。
          trackUserInteractions: true,
          // https://github.com/DataDog/browser-sdk/pull/2378
          storeContextsAcrossPages: true,
          enableExperimentalFeatures: ['feature_flags'],
        });
        datadogRum.startSessionReplayRecording();
        // ref. https://docs.datadoghq.com/ja/logs/log_collection/javascript/
        datadogLogs.init({
          env,
          service,
          clientToken,
          site: site as LogsInitConfiguration['site'],
          version,
          // キャッチできなかった想定外のエラーとかは、Datadog Logs ではなく、Datadog RUM でキャッチできるので、そっちに任せて
          // Datadog Logs 側は、適切にハンドリングできたやつとか、logger.error で出力されるエラーログのみを対象とする
          // Datadog RUM の sampling rate を変更してしまうと予期しないエラーがキャッチできなくなるかもなので、要注意。
          forwardErrorsToLogs: false,
          sessionSampleRate: 100,
          // https://github.com/DataDog/browser-sdk/pull/2378
          storeContextsAcrossPages: true,
          // TODO: ログが大量に連携されていたら、beforeSend で除外する
          // https://app.datadoghq.com/logs?query=service%3Aworkhub-web&cols=host%2Cservice&index=&messageDisplay=inline&stream_sort=time%2Cdesc&viz=stream&from_ts=1672118342401&to_ts=1672119242401&live=true
          beforeSend: log => {
            if (log.http && [0, 401, 404].includes(log.http.status_code)) {
              return false;
            }
            return true;
          },
        });
      }
      Datadog.initialized = true;
    }
  }

  public static setContext(
    people: {
      id: string | undefined;
      type: string | undefined;
      userGroupIds: string[] | undefined;
      personaId?: string;
      superUser?: boolean;
    },
    userGroupRights: Record<string, unknown>[] | undefined,
    authority: Record<string, unknown> | undefined,
    organization: {
      id: string | undefined;
      name: string | undefined;
      ownerOrganizationIds?: string[];
    }
  ) {
    const user = {
      id: people.id,
      persona_id: people.personaId,
      organization_id: organization.id,
      type: people.type,
      super_user: people.superUser,
    };
    const context = {
      people: {
        user_group_ids: people.userGroupIds,
        user_group_rights: userGroupRights,
        authority: authority,
      },
      organization: {
        name: organization.name,
        owner_organization_ids: organization.ownerOrganizationIds,
      },
    };
    datadogRum.setUser(user);
    datadogRum.setGlobalContext(context);
    datadogLogs.setUser(user);
    datadogLogs.setGlobalContext(context);
  }

  public static clearContext() {
    datadogRum.clearUser();
    datadogRum.clearGlobalContext();
    datadogLogs.clearUser();
    datadogLogs.clearGlobalContext();
  }

  public static addFeatureFlagEvaluation(key: string, value: any) {
    datadogRum.addFeatureFlagEvaluation(key, value);
  }
}

export const readVariables = () => {
  const env = import.meta.env['VITE_DATADOG_ENV'] as string;
  const service = import.meta.env['VITE_DATADOG_SERVICE'] as string;
  const clientToken = import.meta.env['VITE_DATADOG_CLIENT_TOKEN'] as string;
  const site = import.meta.env['VITE_DATADOG_SITE'] as string;
  const applicationId = import.meta.env['VITE_DATADOG_APPLICATION_ID'] as string;
  const enabled = import.meta.env['VITE_DATADOG_ENABLED'] as string;
  const apiHost = import.meta.env['VITE_API_HOST'] as string;

  return {
    env,
    service,
    clientToken,
    site,
    applicationId,
    enabled: enabled === 'true',
    allowedTracingUrls: [{match: apiHost, propagatorTypes: ['datadog', 'tracecontext'] as PropagatorType[]}],
  };
};
