import styled from '@emotion/styled';
import {useMutation, useQuery} from '@tanstack/react-query';
import {createFileRoute, useBlocker} from '@tanstack/react-router';
import {Delete, Edit, InfoOutline, Link} from '@workhub/icons';
import {Dialog, IconButton, TextButton} from '@workhub/ui';
import type {AxiosResponse} from 'axios';
import {type ComponentProps, useMemo, useCallback, useState} from 'react';

import {
  createBuildingTenantServiceApi,
  CreateBuildingTenantServiceResponse,
} from '@/api-call/workhub-core/createBuildingTenantServiceApi';
import {deleteBuildingTenantServiceApi} from '@/api-call/workhub-core/deleteBuildingTenantServiceApi';
import {
  getBuildingTenantServicesApi,
  GetBuildingTenantServicesResponse,
} from '@/api-call/workhub-core/getBuildingTenantServicesApi';
import {
  updateBuildingTenantServiceApi,
  UpdateBuildingTenantServiceResponse,
} from '@/api-call/workhub-core/updateBuildingTenantServiceApi';
import StorageBuildingTenantService from '@/common/firebase/storage/reference/organizations/building-tenant-service/storage-building-tenant-service';
import {useDialog} from '@/common/hooks/useDialog';
import useDict, {useBuildingTenantServiceDictDef, useCommonDict} from '@/common/hooks/useDict';
import {useLoginUser} from '@/common/hooks/useLoginUser';
import {useSnackbar} from '@/common/hooks/useSnackbar';
import {Locale} from '@/common/redux/state-types/localeStateType';
import {WHFont} from '@/common/styles/whFont';
import {StringUtils} from '@/common/utils/stringUtils';
import WCardTemplate from '@/components/figma/card/WCardTemplate';
import WLabelContent from '@/components/figma/others/label/WLabelContent';
import WLoadingComponent from '@/components/figma/others/stepper/WLoadingComponent';
import WHeaderNavigation, {type WHeaderActions} from '@/components/header/WHeaderNavigation';
import {WImageRenderer} from '@/components/image/WImageRenderer';
import {WEllipsisTextWithTooltip} from '@/components/tooltip/WEllipsisTextWithTooltip';
import BuildingTenantServiceDeleteDialog from '@/features/building-tenant-management/building-tenant-service-management/BuildingTenantServiceDeleteDialog';
import BuildingTenantServiceFormDialog from '@/features/building-tenant-management/building-tenant-service-management/BuildingTenantServiceFormDialog';
import {Authorize} from '@/routing/Authorize';

const dictDef = {
  add: {
    default: {
      default: '新規追加',
      [Locale.en_US]: 'Add',
    },
  },
  noDataDescription: {
    default: {
      default: 'ビルテナントサービスの登録がありません\n右上の新規追加ボタンからビルテナントサービスを追加してください',
      [Locale.en_US]:
        'There are no registered building tenant services.\nPlease add a building tenant service from the new addition button in the upper right corner.',
    },
  },
  createdSuccessfully: {
    default: {
      default: 'ビルテナントサービスを追加しました',
      [Locale.en_US]: 'Building tenant service successfully added',
    },
  },
  createFailed: {
    default: {
      default: 'ビルテナントサービスの追加に失敗しました',
      [Locale.en_US]: 'Failed to add building tenant service',
    },
  },
  updatedSuccessfully: {
    default: {
      default: 'ビルテナントサービスを更新しました',
      [Locale.en_US]: 'Building tenant service successfully updated',
    },
  },
  updateFailed: {
    default: {
      default: 'ビルテナントサービスの更新に失敗しました',
      [Locale.en_US]: 'Failed to update building tenant service',
    },
  },
};

const MainSectionWrapper = styled.div`
  margin-bottom: var(--spacing-24);
`;

const CardContentWrapper = styled.div`
  display: flex;
  flex-direction: row;

  column-gap: var(--spacing-24);
`;

const TitleWrapper = styled.div`
  display: flex;
  column-gap: var(--spacing-8);
`;

const TitleIconWrapper = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
`;

const TitleTextWrapper = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;

  ${WHFont.titleLarge}
`;

const LinkIconWrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: var(--radius-full);

  background-color: var(--surface-neutral-high);
  color: var(--object-brand-secondary);
`;

const PublishScopeTenantDialogContent = styled.div`
  ${WHFont.bodyMediumStrong};
  color: var(--text-neutral-secondary);
  gap: var(--spacing-16);

  > ul {
    list-style: none;
    padding: 0;
  }

  > ul > li {
    ${WHFont.bodyLargeStrong};
    color: var(--text-neutral-primary);
    margin-bottom: var(--spacing-8);
  }
`;

const PublishScopeTenant = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  align-items: center;

  column-gap: var(--spacing-8);
  row-gap: var(--spacing-8);
`;

const EmptyStateBackground = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: var(--spacing-40);
  padding-bottom: var(--spacing-40);
  height: 180px;

  text-align: center;
  ${WHFont.labelMedium};
`;

// FIXME: workhub-ui に tag が作成されたら置き換える
const SuccessStatusTag = styled.span`
  color: var(--text-semantic-success);
  border-radius: var(--radius-s);
  background-color: var(--surface-semantic-success-light);
  padding-top: var(--spacing-4);
  padding-bottom: var(--spacing-4);
  padding-left: var(--spacing-8);
  padding-right: var(--spacing-8);
  gap: var(--spacing-4);

  ${WHFont.labelMediumStrong};
`;

const ValidStatusTag = styled.span`
  color: var(--text-neutral-secondary);
  border-radius: var(--radius-s);
  background-color: var(--surface-neutral-high);
  padding-top: var(--spacing-4);
  padding-bottom: var(--spacing-4);
  padding-left: var(--spacing-8);
  padding-right: var(--spacing-8);
  gap: var(--spacing-4);

  ${WHFont.labelMediumStrong};
`;

const SiteUrlText = styled.a`
  color: var(--text-neutral-primary);
  ${WHFont.bodyLarge};
`;

const LargeStrongText = styled.span`
  ${WHFont.bodyLargeStrong};
`;

const EmptyStateText = styled.span`
  white-space: pre-wrap;

  color: var(--text-neutral-primary);

  ${WHFont.bodyLargeStrong};
`;

export type BuildingTenantService = GetBuildingTenantServicesResponse['items'][0] & {logoUrl?: string};
export type BuildingTenantServiceDeleteData = {id: string; logoImage?: string};

export const Route = createFileRoute('/_authorized/building-tenant-management/building-tenant-service/')({
  component: () => (
    <Authorize featureGroup='BuildingTenantManagement' feature='BuildingTenantServiceManagement'>
      <RouteComponent />
    </Authorize>
  ),
});

function RouteComponent() {
  const dict = useDict(dictDef);
  const commonDict = useCommonDict();
  const buildingTenantServiceDict = useBuildingTenantServiceDictDef();
  const {organizationId} = useLoginUser();
  const snackbar = useSnackbar();

  //ビルテナントサービスを取得
  const {
    data: buildingTenantServices,
    isLoading,
    refetch,
  } = useQuery<BuildingTenantService[]>({
    queryKey: ['building-tenant-service', organizationId],
    queryFn: async () => {
      const {data} = await getBuildingTenantServicesApi();
      const dataWithLogoUrl = await Promise.all(
        data.items.map(async item => {
          if (!item.logoImage) return item;
          const url = await StorageBuildingTenantService.loadLogo(organizationId, item.id, item.logoImage);
          return {...item, logoUrl: url};
        })
      );

      return dataWithLogoUrl.sort((pre, cur) => StringUtils.sortJapaneseStrArrayCondition(pre.name, cur.name));
    },
  });

  const [selectedService, setSelectedService] = useState<BuildingTenantService>();
  const isEditing = selectedService != null;

  type MutateInput = {
    id?: string | undefined;
    name: string;
    description?: string | undefined;
    siteUrl: string;
    logoImage?: string | undefined;
    logoImageToDelete?: string | undefined;
    logoImageFile?: File | undefined;
    isPublished: boolean;
    scopeTenantsIds: string[];
  };

  const {mutate, isPending} = useMutation({
    mutationFn: async ({
      id,
      name,
      description,
      siteUrl,
      logoImage,
      logoImageToDelete,
      logoImageFile,
      isPublished,
      scopeTenantsIds,
    }: MutateInput) => {
      let result: AxiosResponse<CreateBuildingTenantServiceResponse | UpdateBuildingTenantServiceResponse>;
      if (id) {
        result = await updateBuildingTenantServiceApi({
          paths: {id},
          body: {name, description, siteUrl, logoImage, isPublished, scopeTenantsIds},
        });
      } else {
        result = await createBuildingTenantServiceApi({
          body: {name, description, siteUrl, logoImage, isPublished, scopeTenantsIds},
        });
      }

      //新しいロゴ画像が設定されていたら画像をアップロード
      if (logoImage && logoImageFile) {
        await StorageBuildingTenantService.uploadLogo(organizationId, result.data.id, logoImage, logoImageFile);
      }

      // 古いロゴ画像を削除
      //①あり→なし
      //②あり→あり（画像が変わった場合）
      if (logoImageToDelete && (!logoImage || logoImageToDelete !== logoImage)) {
        await StorageBuildingTenantService.deleteLogo(organizationId, result.data.id, logoImageToDelete);
      }
    },
    onSuccess: async () => {
      void (await refetch());
      snackbar.success(isEditing ? dict.updatedSuccessfully : dict.createdSuccessfully);
      handleCloseFormDialog();
    },
    onError: () => {
      snackbar.fail(isEditing ? dict.updateFailed : dict.createFailed);
    },
  });

  useBlocker({
    shouldBlockFn: () => isPending,
    disabled: !isPending,
  });

  const {open: openFormDialog, onClose: onCloseFormDialog, onOpen: onOpenFormDialog} = useDialog();

  const formDefaultValue: ComponentProps<typeof BuildingTenantServiceFormDialog>['defaultValue'] = useMemo(
    () =>
      selectedService && {
        name: selectedService.name,
        siteUrl: selectedService.siteUrl,
        description: selectedService.description,
        logoImage: selectedService.logoImage,
        logoUrl: selectedService.logoUrl,
        isPublished: selectedService.isPublished,
        scopeTenantIds: selectedService.scopeTenants.map(t => t.tenantOrgId),
      },
    [selectedService]
  );

  const onClickEdit = (service: BuildingTenantService) => {
    setSelectedService(service);
    onOpenFormDialog();
  };

  const handleOK: ComponentProps<typeof BuildingTenantServiceFormDialog>['onOK'] = useCallback(
    value => {
      mutate({
        id: selectedService?.id,
        name: value.name,
        description: value.description,
        siteUrl: value.siteUrl,
        logoImage: value.logoImage,
        logoImageToDelete: selectedService?.logoImage,
        logoImageFile: value.logoImageFile,
        isPublished: value.isPublished,
        scopeTenantsIds: value.scopeTenantIds,
      });
    },
    [mutate, selectedService]
  );

  const handleCloseFormDialog = useCallback(() => {
    onCloseFormDialog();
    setSelectedService(undefined);
  }, [onCloseFormDialog]);

  const {open: openDeleteDialog, onClose: onCloseDeleteDialog, onOpen: onOpenDeleteDialog} = useDialog();

  const onClickDelete = (service: BuildingTenantService) => {
    setSelectedService(service);
    onOpenDeleteDialog();
  };

  const handleCloseDeleteDialog = useCallback(() => {
    onCloseDeleteDialog();
    setSelectedService(undefined);
  }, [onCloseDeleteDialog]);

  const handleDelete = useCallback(
    async ({id, logoImage}: BuildingTenantServiceDeleteData) => {
      await deleteBuildingTenantServiceApi({paths: {id}});

      if (logoImage) await StorageBuildingTenantService.deleteLogo(organizationId, id, logoImage);

      await refetch();
    },
    [organizationId, refetch]
  );

  const onClickAdd = useCallback(() => {
    setSelectedService(undefined);
    onOpenFormDialog();
  }, [onOpenFormDialog]);

  const actions = useMemo<WHeaderActions>(
    () => ({
      primary: {
        label: dict.add,
        action: onClickAdd,
      },
    }),
    [dict.add, onClickAdd]
  );

  return (
    <>
      <header>
        <WHeaderNavigation
          title={buildingTenantServiceDict.buildingTenantServiceManagement}
          navigation={buildingTenantServiceDict.buildingTenantManagement}
          actions={actions}
        />
      </header>
      <section>
        <MainSectionWrapper>
          {isLoading ? (
            <WLoadingComponent message={commonDict.loading} notTransparent />
          ) : buildingTenantServices?.length ? (
            <>
              {buildingTenantServices?.map(service => {
                return (
                  <WCardTemplate
                    key={service.id}
                    title={
                      <TitleWrapper>
                        {service.logoUrl ? (
                          <TitleIconWrapper>
                            <WImageRenderer imageUrl={service.logoUrl} borderRadius={8} loading={isLoading} />
                          </TitleIconWrapper>
                        ) : (
                          <LinkIconWrapper>
                            <Link color={'inherit'} />
                          </LinkIconWrapper>
                        )}

                        <TitleTextWrapper>{service.name}</TitleTextWrapper>
                      </TitleWrapper>
                    }
                    marginTop={16}
                    marginLeft={16}
                    marginRight={16}
                    marginBottom={16}
                    iconButton={
                      <>
                        <IconButton title={commonDict.delete} onClick={() => onClickDelete(service)}>
                          <Delete color={'error'} />
                        </IconButton>
                        <IconButton title={commonDict.edit} onClick={() => onClickEdit(service)}>
                          <Edit />
                        </IconButton>
                      </>
                    }
                  >
                    <CardContentWrapper>
                      <WLabelContent
                        flex={1}
                        width='60%'
                        label={buildingTenantServiceDict.description}
                        marginTop={'var(--spacing-24)'}
                        marginBottom={'var(--spacing-24)'}
                        content={<LargeStrongText>{service.description ?? commonDict.noData}</LargeStrongText>}
                      />
                      <WLabelContent
                        flex={1}
                        width='40%'
                        label={buildingTenantServiceDict.url}
                        marginTop={'var(--spacing-24)'}
                        marginBottom={'var(--spacing-24)'}
                        content={
                          <SiteUrlText href={service.siteUrl} target='_blank'>
                            <WEllipsisTextWithTooltip message={service.siteUrl} content={service.siteUrl} />
                          </SiteUrlText>
                        }
                      />
                    </CardContentWrapper>
                    <CardContentWrapper>
                      <WLabelContent
                        flex={1}
                        label={buildingTenantServiceDict.VisibilitySetting}
                        content={
                          service.isPublished ? (
                            <SuccessStatusTag>{buildingTenantServiceDict.public}</SuccessStatusTag>
                          ) : (
                            <ValidStatusTag>{buildingTenantServiceDict.private}</ValidStatusTag>
                          )
                        }
                      />
                      <WLabelContent
                        flex={1}
                        label={buildingTenantServiceDict.scopeTenant}
                        content={
                          <PublishScopeTenant>
                            <PublishScopeTenantContent buildingTenantService={service} />
                          </PublishScopeTenant>
                        }
                      />
                    </CardContentWrapper>
                  </WCardTemplate>
                );
              })}
            </>
          ) : (
            <EmptyStateBackground>
              <EmptyStateText>{dict.noDataDescription}</EmptyStateText>
            </EmptyStateBackground>
          )}
        </MainSectionWrapper>
      </section>
      {openFormDialog && (
        <BuildingTenantServiceFormDialog
          open={openFormDialog}
          processing={isPending}
          defaultValue={formDefaultValue}
          onOK={handleOK}
          onClose={handleCloseFormDialog}
        />
      )}
      {selectedService && (
        <BuildingTenantServiceDeleteDialog
          open={openDeleteDialog}
          onClose={handleCloseDeleteDialog}
          onDelete={handleDelete}
          id={selectedService.id}
          logoImage={selectedService.logoImage}
        />
      )}
    </>
  );
}

const PublishScopeTenantContent = ({buildingTenantService}: {buildingTenantService: BuildingTenantService}) => {
  const buildingTenantServiceDict = useBuildingTenantServiceDictDef();
  const dict = useCommonDict();
  //scopeTenants が空の場合はすべてのテナントに公開するとみなす
  if (!buildingTenantService.scopeTenants.length) {
    return <LargeStrongText>{buildingTenantServiceDict.publishToAll}</LargeStrongText>;
  }

  // TODO: デザインコンポーネントにモードレスダイアログが追加されたら置き換える
  return (
    <>
      <Dialog.Trigger>
        <TextButton color='secondary'>
          <InfoOutline />
          {buildingTenantServiceDict.publishScopeTenantCount(buildingTenantService.scopeTenants.length)}
        </TextButton>
        <Dialog>
          <Dialog.Title>{buildingTenantServiceDict.scopeTenant}</Dialog.Title>
          <Dialog.Content>
            <PublishScopeTenantDialogContent>
              {buildingTenantServiceDict.publishScopeTenantCount(buildingTenantService.scopeTenants.length)}
              <ul>
                {buildingTenantService.scopeTenants.map(tenant => (
                  <li key={tenant.tenantOrgId}>{tenant.tenantOrgName}</li>
                ))}
              </ul>
            </PublishScopeTenantDialogContent>
          </Dialog.Content>
          <Dialog.Actions>
            <Dialog.Close>
              {({close}) => (
                <TextButton color={'secondary'} onClick={close}>
                  {dict.close}
                </TextButton>
              )}
            </Dialog.Close>
          </Dialog.Actions>
        </Dialog>
      </Dialog.Trigger>
    </>
  );
};
