import styled from '@emotion/styled';
import {useMutation, useQuery} from '@tanstack/react-query';
import {createFileRoute} from '@tanstack/react-router';
import {Dialog, Loading, TextButton} from '@workhub/ui';
import {ComponentRef, useEffect, useRef, useState} from 'react';
import * as R from 'remeda';

import {getAnnouncementApi, GetAnnouncementResponse} from '@/api-call/workhub-core/getAnnouncementApi';
import {updateAnnouncementApi} from '@/api-call/workhub-core/updateAnnouncementApi';
import {useDialog} from '@/common/hooks/useDialog';
import useDict from '@/common/hooks/useDict';
import {useSnackbar} from '@/common/hooks/useSnackbar';
import {jpDate} from '@/common/jpDate';
import {Locale} from '@/common/redux/state-types/localeStateType';
import WHeaderNavigation from '@/components/header/WHeaderNavigation';
import {RichTextEditor} from '@/components/rich-text-editor/RichTextEditor';
import AnnouncementConfirmCancelDialog from '@/features/building-tenant-management/announcement/AnnouncementConfirmCancelDialog';
import AnnouncementPublishSettingFormDialog, {
  BuildingAnnouncementPublishSettingValue,
} from '@/features/building-tenant-management/announcement/AnnouncementPublishSettingFormDialog';
import AnnouncementTitleAndContentForm from '@/features/building-tenant-management/announcement/AnnouncementTitleAndContentForm';
import {Authorize} from '@/routing/Authorize';

const dictDef = {
  navigation1: {
    default: {
      default: 'ビルテナント管理',
      [Locale.en_US]: 'Building Tenant Management',
    },
  },
  navigation2: {
    default: {
      default: 'お知らせ管理',
      [Locale.en_US]: 'Announcement Management',
    },
  },
  newAnnouncement: {
    default: {
      default: 'お知らせ',
      [Locale.en_US]: 'Announcement',
    },
  },
  save: {
    default: {
      default: '保存',
      [Locale.en_US]: 'Save',
    },
  },
  cancel: {
    default: {
      default: 'キャンセル',
      [Locale.en_US]: 'Cancel',
    },
  },
  toPublishSetting: {
    default: {
      default: '続けて公開設定へ',
      [Locale.en_US]: 'To Publish Setting',
    },
  },
  scceededToUpdateAsDraft: {
    default: {
      default: 'ステータスを下書きに変更しました',
      [Locale.en_US]: 'The publish status is changed to draft.',
    },
  },
  succeededToUpdateAsDraft: {
    default: {
      default: 'お知らせを編集しました。公開するには公開設定を行ってください。',
      [Locale.en_US]: 'Announcement edited, please set the publish setting to publish.',
    },
  },
  succeededToSchedule: {
    default: {
      default: 'お知らせを編集しました。予約日時に自動的に公開されます。',
      [Locale.en_US]: 'Announcement edited. It will be automatically published on scheduled time.',
    },
  },
  succeededToPublish: {
    default: {
      default: 'お知らせを公開しました。',
      [Locale.en_US]: 'Announcement published.',
    },
  },
  failedToEdit: {
    default: {
      default: '編集に失敗しました',
      [Locale.en_US]: 'Failed to edit',
    },
  },
  cancelAsDraftDialogTitle: {
    default: {
      default: '公開ステータスが下書きのままですがよろしいですか？',
      [Locale.en_US]: 'The publish status is still in draft, are you sure?',
    },
  },
  saveAsDraftDialogTitle: {
    default: {
      default: '公開ステータスが下書きのまま保存されます。よろしいですか？',
      [Locale.en_US]: 'The publish status will be saved as a draft. Are you sure?',
    },
  },
  dialogDescription: {
    default: {
      default: '下書きのままだと公開されません。公開に進む場合は設定を行ってください。',
      [Locale.en_US]:
        'If left as a draft, it will not be published. If you wish to publish, proceed to Publish Setting.',
    },
  },
  back: {
    default: {
      default: '戻る',
      [Locale.en_US]: 'Back',
    },
  },
  proceedToPublishSetting: {
    default: {
      default: '公開設定へ',
      [Locale.en_US]: 'Proceed to Publish Setting',
    },
  },
  ok: {
    default: {
      default: 'OK',
      [Locale.en_US]: 'OK',
    },
  },
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  box-sizing: border-box;
  overflow: hidden;
`;

const Layout = styled.div`
  padding: var(--spacing-24);
  flex: 1;
  overflow: auto;
`;

export const Route = createFileRoute('/_authorized/building-tenant-management/announcement/$announcementId/edit')({
  component: () => (
    <Authorize featureGroup='BuildingTenantManagement' feature='BuildingAnnouncementManagement'>
      <RouteComponent />
    </Authorize>
  ),
});

function RouteComponent() {
  const dict = useDict(dictDef);
  const snackbar = useSnackbar();
  const navigate = Route.useNavigate();
  const {announcementId} = Route.useParams();
  const {open: openPublishSetting, onOpen: onOpenPublishSetting, onClose: onClosePublishSetting} = useDialog();
  const {
    open: openConfirmCancelAsDraftDialog,
    onOpen: onOpenConfirmCancelAsDraftDialog,
    onClose: onCloseConfirmCancelAsDraftDialog,
  } = useDialog();
  const {
    open: openConfirmSaveAsDraftDialog,
    onOpen: onOpenConfirmSaveAsDraftDialog,
    onClose: onCloseConfirmSaveAsDraftDialog,
  } = useDialog();
  const [forceDiscard, setForceDiscard] = useState(false);

  const initialTitleRef = useRef<string>();
  const titleRef = useRef<HTMLInputElement>(null);

  const initialEditorRef = useRef<GetAnnouncementResponse['content']>();
  const editorRef = useRef<ComponentRef<typeof RichTextEditor>>(null);

  const [isTitleOverFifty, setIsTitleOverFifty] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const title = titleRef.current?.value;
      const hasTitleChanged = title !== initialTitleRef.current;
      const hasEditorChanged = !R.isDeepEqual(editorRef.current?.getJson(), initialEditorRef.current);
      setIsTitleOverFifty(title !== undefined && title.length > 50);
      setHasChanged(hasTitleChanged || hasEditorChanged);
    }, 1000); // 1000msごとにチェック

    return () => clearInterval(intervalId);
  }, []);

  const {data: announcement, isLoading} = useQuery({
    queryKey: ['building-tenant-management/announcement', announcementId],
    queryFn: async () => {
      const {data} = await getAnnouncementApi({
        paths: {announcementId},
      });
      initialTitleRef.current = data.title;
      initialEditorRef.current = data.content;
      return data;
    },
  });

  useEffect(() => {
    if (titleRef.current && announcement?.title) {
      titleRef.current.value = announcement.title;
    }
  }, [announcement?.title]);

  const {mutate: update, isPending: isPendingUpdate} = useMutation({
    mutationKey: ['updateAnnouncement'],
    mutationFn: async (value: BuildingAnnouncementPublishSettingValue) => {
      const title = titleRef.current?.value;
      const content = editorRef.current?.getJson();
      const contentIsEmpty = editorRef.current?.getIsEmpty();
      if (!title || !content || contentIsEmpty) {
        throw new Error('content is empty');
      }
      // TODO: ここで画像をFireStorageに保存する
      await updateAnnouncementApi({
        paths: {announcementId},
        body: {
          title,
          content,
          publishImmediately: value.publishStatus === 'published',
          scheduledAt: value.publishStatus === 'scheduled' ? value.scheduledAt?.toISOString() : undefined,
          scopeTenantsIds: value.scopeTenantsIds,
        },
      });
      return value.publishStatus;
    },
    onSuccess: publishStatus => {
      const snackBarMessage = (() => {
        switch (publishStatus) {
          case 'draft':
            return dict.succeededToUpdateAsDraft;
          case 'scheduled':
            return dict.succeededToSchedule;
          case 'published':
            return dict.succeededToPublish;
        }
      })();
      snackbar.success(snackBarMessage);
      navigate({to: `/building-tenant-management/announcement/${announcementId}`});
    },
    onError: () => {
      snackbar.fail(dict.failedToEdit);
    },
  });

  const {mutate: temporarilyUpdateAsDraft, isPending: isPendingTemporarilySaveAsDraft} = useMutation({
    mutationKey: ['temporarilyUpdateAnnouncementAsDraft'],
    mutationFn: async () => {
      if (!announcement) {
        return;
      }
      await updateAnnouncementApi({
        paths: {announcementId},
        body: {
          title: announcement.title,
          content: announcement.content,
          scheduledAt: undefined,
          scopeTenantsIds: [], // TODO: 別ブランチで実装
        },
      });
    },
    onSuccess: () => {
      snackbar.info(dict.scceededToUpdateAsDraft);
    },
    onError: () => {
      // ここでエラーになるケースは想定外の問題が起きているケースなので、とりあえず詳細画面にリダイレクトさせる
      navigate({to: `/building-tenant-management/announcement/${announcementId}`});
      return null;
    },
  });

  // 編集画面を開いたタイミングで、もし下書きではないお知らせだったら、一度そのお知らせを下書きに更新する
  useEffect(() => {
    if (announcement && announcement.status !== 'draft') {
      temporarilyUpdateAsDraft();
    }
  }, [announcement, temporarilyUpdateAsDraft]);

  if (isLoading) {
    return <Loading centered />;
  }

  if (!announcement) {
    return null;
  }

  if (announcement.status !== 'draft' && announcement.status !== 'scheduled') {
    navigate({to: `/building-tenant-management/announcement/${announcementId}`});
    return null;
  }

  if (isPendingTemporarilySaveAsDraft) {
    return <Loading centered />;
  }

  return (
    <Root>
      <WHeaderNavigation
        title={dict.newAnnouncement}
        navigation={[
          {label: dict.navigation1},
          {label: dict.navigation2, toPath: '/building-tenant-management/announcement'},
        ]}
        actions={{
          items: [
            {
              label: dict.cancel,
              action: () => {
                // もともと下書きのお知らせであれば、編集キャンセル時はそのまま詳細画面に戻ってもよいが、
                // もともと下書きではないお知らせだった場合、 temporarilySaveAsDraft によって下書きに更新されているはずなので、
                // そのままキャンセルすると下書きに変更されたままになってしまうことをダイアログで注意喚起する
                if (announcement.status === 'draft') {
                  navigate({to: `/building-tenant-management/announcement/${announcementId}`});
                } else {
                  onOpenConfirmCancelAsDraftDialog();
                }
              },
              buttonColor: 'secondary',
              disabled: isPendingUpdate,
            },
            {
              label: dict.save,
              action: () => {
                // もともと下書きだったものを下書きのまま保存する場合は確認不要でupdateしてOK
                // もともと下書きではない場合は公開予約済みだったはずなので、この導線から保存すると下書き扱いで保存することをダイアログで注意喚起する
                if (announcement.status === 'draft') {
                  update({publishStatus: 'draft', scheduledAt: null, scopeTenantsIds: []});
                } else {
                  onOpenConfirmSaveAsDraftDialog();
                }
              },
              buttonColor: 'secondary',
              disabled: isTitleOverFifty || isPendingUpdate,
              running: isPendingUpdate,
            },
            {
              label: dict.toPublishSetting,
              action: onOpenPublishSetting,
              buttonColor: 'primary',
              disabled: isTitleOverFifty || isPendingUpdate,
            },
          ],
        }}
      />

      <Layout>
        <AnnouncementTitleAndContentForm
          isTitleOverFifty={isTitleOverFifty}
          titleRef={titleRef}
          editorRef={editorRef}
          editorContent={announcement.content}
        />
      </Layout>

      <AnnouncementPublishSettingFormDialog
        open={openPublishSetting}
        inputValue={{
          publishStatus: announcement.status,
          scheduledAt: announcement.scheduledAt ? jpDate(announcement.scheduledAt) : null,
          scopeTenantsIds: announcement.scopeTenants.map(t => t.tenantOrgId),
        }}
        onOk={value => update(value)}
        onClose={onClosePublishSetting}
        isPending={isPendingUpdate}
      />

      {/* 編集を破棄して詳細画面に戻ってもよいかを確認するダイアログ */}
      <AnnouncementConfirmCancelDialog
        isPending={isPendingUpdate}
        hasChanged={hasChanged}
        forceDiscard={forceDiscard}
      />

      {/* 編集を破棄して詳細画面に戻ったときに、ステータスが下書きに変わったままになってしまってもよいかを確認するダイアログ */}
      <Dialog open={openConfirmCancelAsDraftDialog}>
        <Dialog.Title>{dict.cancelAsDraftDialogTitle}</Dialog.Title>
        <Dialog.Content>{dict.dialogDescription}</Dialog.Content>
        <Dialog.Actions>
          <TextButton color='secondary' onClick={onCloseConfirmCancelAsDraftDialog}>
            {dict.back}
          </TextButton>
          <TextButton
            color='secondary'
            onClick={() => {
              onCloseConfirmCancelAsDraftDialog();
              onOpenPublishSetting();
            }}
          >
            {dict.proceedToPublishSetting}
          </TextButton>
          <TextButton
            color='primary'
            onClick={() => {
              setForceDiscard(true);
              navigate({to: `/building-tenant-management/announcement/${announcementId}`});
            }}
          >
            {dict.ok}
          </TextButton>
        </Dialog.Actions>
      </Dialog>

      {/* ステータスが下書きに変わったまま保存してもよいかを確認するダイアログ */}
      <Dialog open={openConfirmSaveAsDraftDialog}>
        <Dialog.Title>{dict.saveAsDraftDialogTitle}</Dialog.Title>
        <Dialog.Content>{dict.dialogDescription}</Dialog.Content>
        <Dialog.Actions>
          <TextButton color='secondary' onClick={onCloseConfirmSaveAsDraftDialog} disabled={isPendingUpdate}>
            {dict.back}
          </TextButton>
          <TextButton
            color='secondary'
            onClick={() => {
              onCloseConfirmSaveAsDraftDialog();
              onOpenPublishSetting();
            }}
            disabled={isPendingUpdate}
          >
            {dict.proceedToPublishSetting}
          </TextButton>
          <TextButton
            color='primary'
            onClick={() => update({publishStatus: 'draft', scheduledAt: null, scopeTenantsIds: []})}
            processing={isPendingUpdate}
            disabled={isPendingUpdate}
          >
            {dict.ok}
          </TextButton>
        </Dialog.Actions>
      </Dialog>
    </Root>
  );
}
