import {V2CardType} from '@bitkey-service/v2_core-types/lib/enum/cardType';
import {UserGroupType} from '@bitkey-service/v2_core-types/lib/store/organizations/user-groups/v2_storeTypesOrgUserGroup';
import styled from '@emotion/styled';
import {createFileRoute} from '@tanstack/react-router';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useClient, useQuery} from 'urql';
import * as z from 'zod';

import {ActivationGroup} from '@/common/feature-control/featureDefinitions';
import {FirestoreOrgCustomers} from '@/common/firebase/firestore/references/firestoreOrgCustomers';
import {FirestoreOrgUserGroups} from '@/common/firebase/firestore/references/firestoreOrgUserGroups';
import {useDebouncedState} from '@/common/hooks/useDebounceFilter';
import useDict from '@/common/hooks/useDict';
import {useLoginUser} from '@/common/hooks/useLoginUser';
import {withSearchState} from '@/common/hooks/useSearchState';
import useWPagination from '@/common/hooks/useWPagination';
import {Locale} from '@/common/redux/state-types/localeStateType';
import ArrayUtil from '@/common/utils/arrayUtil';
import WHeaderTab2 from '@/components/figma/header/WHeaderTab2';
import WHeaderNavigation, {WHeaderActions} from '@/components/header/WHeaderNavigation';
import WHeaderSearch from '@/components/header/WHeaderSearch';
import {WFacetSearchSelectModelsProps} from '@/components/search/WFacetSearchSelectModels';
import {WFacetSelectProps} from '@/components/search/WFacetSelect';
import WNfcCardAddToGroupByCsvDialog from '@/features/nfc-card/add/csv/WNfcCardAddByCsvDialog';
import WNfcRegistrationDialog from '@/features/nfc-card/add/WNfcRegistrationDialog';
import WNfcCardDownloadCsvDialog from '@/features/nfc-card/csv/WNfcCardCsvDownloadDialog';
import WNfcCardTable from '@/features/nfc-card/table/WNfcCardTable';
import WNfcEmployeeCardTable from '@/features/nfc-card/table/WNfcEmployeeCardTable';
import WNfcLendingCardTable from '@/features/nfc-card/table/WNfcLendingCardTable';
import {graphql} from '@/gql';
import {FetchNfcCardQueryQuery, IdInput, NfcCardStatus, NfcCardTypeInput} from '@/gql/graphql';

const tabIds = ['all', 'employee', 'lending', 'memberCard'] as const;

type HeaderTabId = (typeof tabIds)[number];

const statuses = ['lendable', 'lending', 'exceeded', 'inUse', 'notInUse'] as const;

export type Status = (typeof statuses)[number];

/** タブによって暗黙的に絞り込まれるカードの種類. これに加えてユーザが独自に絞り込む場合もある. */
const getBaseFilter = (tabId: HeaderTabId): V2CardType[] => {
  if (tabId === 'employee') {
    return [V2CardType.Employee];
  } else if (tabId === 'lending') {
    return [V2CardType.Lending];
  } else if (tabId === 'memberCard') {
    return [V2CardType.ThirdPlaceMemberCard];
  } else {
    return [];
  }
};

const searchSchema = z.object({
  tabId: z.enum(tabIds).optional().catch(undefined),
  searchWord: z.string().optional().catch(undefined),
  status: z.array(z.enum(statuses)).optional().catch(undefined),
  cardType: z.array(z.nativeEnum(V2CardType)).optional().catch(undefined),
  ownerIds: z.array(z.string()).optional().catch(undefined),
  borrowerIds: z.array(z.string()).optional().catch(undefined),
  groupIds: z.array(z.string()).optional().catch(undefined),
});

const {validateSearch, useSearchState} = withSearchState({
  schema: searchSchema,
});

export const Route = createFileRoute('/_authorized/nfc-cards/')({
  component: RouteComponent,
  validateSearch,
});

const Root = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const dictDef = {
  title: {
    default: {
      default: 'セキュリティカード',
      [Locale.en_US]: 'Security Cards',
    },
  },
  navigation: {
    default: {
      default: 'セキュリティカード管理 /',
      [Locale.en_US]: 'Security Card Management',
    },
  },
  all: {
    default: {
      default: 'すべて',
      [Locale.en_US]: 'All',
    },
  },
  registerCSV: {
    default: {
      default: 'CSV取り込み',
      [Locale.en_US]: 'Register CSV',
    },
  },
  newAdd: {
    default: {
      default: '新規追加',
      [Locale.en_US]: 'New Add',
    },
  },
  csvExport: {
    default: {
      default: 'CSV出力',
      [Locale.en_US]: 'CSV Download',
    },
  },
  employeeIdCard: {
    default: {
      default: '社員証',
      [Locale.en_US]: 'Employee Id Card',
    },
  },
  lendingCard: {
    default: {
      default: '一時利用カード',
      [Locale.en_US]: 'Temporary Use Card',
    },
  },
  memberCard: {
    default: {
      default: '会員証',
      [Locale.en_US]: 'Member Card',
    },
  },
};

function RouteComponent() {
  const dict = useDict(dictDef);
  const user = useLoginUser();

  const [
    {
      tabId = 'employee',
      searchWord = '',
      status = [],
      cardType = getBaseFilter(tabId),
      ownerIds = [],
      borrowerIds = [],
      groupIds = [],
    },
    setSearchState,
  ] = useSearchState(Route);

  const [customersIds, setCustomersIds] = useState<string[]>([]);
  const [thirdPlaceUserGroupIds, setThirdPlaceUserGroupIds] = useState<string[]>([]);
  const useThirdPlace = useMemo(
    // third-placeを利用していたらコワーキング用の会議室一覧を表示している、、
    () => !!user.activations.find(a => a.activationGroup === ('thirdPlace' as ActivationGroup)),
    [user.activations]
  );

  useEffect(() => {
    Promise.all([
      useThirdPlace ? FirestoreOrgCustomers.getAll(user.organizationId) : [],
      FirestoreOrgUserGroups.getByTypes(user.organizationId, [UserGroupType.Tenant]),
    ]).then(([customers, userGroups]) => {
      setThirdPlaceUserGroupIds(userGroups.map(userGroup => userGroup.id));
      setCustomersIds(customers.map(customer => customer.id));
    });
  }, [useThirdPlace, user.organizationId]);

  const headerTabs = useMemo<{id: HeaderTabId; label: string}[]>(() => {
    if (useThirdPlace) {
      return [
        {
          id: 'employee',
          label: dict.employeeIdCard,
        },
        {
          id: 'lending',
          label: dict.lendingCard,
        },
        {
          id: 'memberCard',
          label: dict.memberCard,
        },
        {
          id: 'all',
          label: dict.all,
        },
      ];
    } else {
      return [
        {
          id: 'employee',
          label: dict.employeeIdCard,
        },
        {
          id: 'lending',
          label: dict.lendingCard,
        },
        {
          id: 'all',
          label: dict.all,
        },
      ];
    }
  }, [dict.all, dict.employeeIdCard, dict.lendingCard, dict.memberCard, useThirdPlace]);

  const onChangeHeaderTab = useCallback(
    (tabId: HeaderTabId) => {
      setSearchState(prev => ({
        tabId,
        searchWord: prev.searchWord,
      }));
    },
    [setSearchState]
  );

  const [openCsvDialog, setOpenCsvDialog] = useState<boolean>(false);
  const openCsvAddDialog = useCallback(() => setOpenCsvDialog(true), []);
  const closeCsvAddDialog = useCallback(() => setOpenCsvDialog(false), []);
  const [openDownloadCsvDialog, setDownloadCsvDialog] = useState<boolean>(false);
  const onOpenDownloadCsvDialog = useCallback(() => setDownloadCsvDialog(true), []);
  const onCloseDownloadCsvDialog = useCallback(() => setDownloadCsvDialog(false), []);

  const [openManualDialog, setOpenManualDialog] = useState<boolean>(false);
  const openManualAddDialog = useCallback(() => setOpenManualDialog(true), []);
  const closeManualAddDialog = useCallback(() => setOpenManualDialog(false), []);
  const headerActions = useMemo<WHeaderActions>(() => {
    return {
      primary: {
        label: dict.registerCSV,
        action: openCsvAddDialog,
      },
      secondary: {
        label: dict.newAdd,
        action: openManualAddDialog,
      },
      others: [
        {
          label: dict.csvExport,
          action: onOpenDownloadCsvDialog,
        },
      ],
    };
  }, [dict.csvExport, dict.newAdd, dict.registerCSV, onOpenDownloadCsvDialog, openCsvAddDialog, openManualAddDialog]);

  const filter = useMemo(
    () => ({
      types: cardType,
      status,
      ownerIds,
      borrowerIds,
      searchWord,
      groupIds,
    }),
    [searchWord, borrowerIds, ownerIds, status, cardType, groupIds]
  );

  const {offset, perPage, onNext, onPrev, handleChangePageSize} = useWPagination();
  const {nfcCards, totalCount, fetching, refetch} = useNfcCardQuery({
    organizationId: user.organizationId,
    searchWord,
    type: cardType.length ? {in: filter.types} : undefined,
    ownerId: ownerIds.length ? {in: filter.ownerIds} : undefined,
    borrowerId: borrowerIds.length ? {in: filter.borrowerIds} : undefined,
    groupId: groupIds.length ? {in: filter.groupIds} : undefined,
    status: status.length ? filter.status : undefined,
    skip: offset,
    take: perPage,
  });

  // ページ外含めてすべて選択する用
  const getAllIds = useAllNfcCardIds({
    organizationId: user.organizationId,
    searchWord,
    type: cardType.length ? {in: filter.types} : undefined,
    ownerId: ownerIds.length ? {in: filter.ownerIds} : undefined,
    borrowerId: borrowerIds.length ? {in: filter.borrowerIds} : undefined,
    groupId: groupIds.length ? {in: filter.groupIds} : undefined,
    status: status.length ? filter.status : undefined,
  });
  // ページ内をすべて選択する用
  // 最終的に渡す先が () => Promise<string[]> を求めている
  const getRowIdsOnPage = useCallback(async () => {
    if (!nfcCards) return [];
    return nfcCards.map(nfcCard => nfcCard.id);
  }, [nfcCards]);

  const onSelectTypes = useCallback(
    (cardType: V2CardType[]): void => {
      setSearchState(prev => ({...prev, cardType}));
    },
    [setSearchState]
  );

  const onSelectStatuses = useCallback(
    (status: Status[]): void => {
      setSearchState(prev => ({...prev, status}));
    },
    [setSearchState]
  );

  return (
    <Root>
      <WHeaderNavigation title={dict.title} navigation={dict.navigation} actions={headerActions} />
      <WHeaderTab2 tabs={headerTabs} tabId={tabId} onChange={onChangeHeaderTab} />
      {tabId === 'all' && (
        <AllNfcCards
          useThirdPlace={useThirdPlace}
          searchWord={searchWord}
          onChangeSearchWord={(word: string) => setSearchState(prev => ({...prev, searchWord: word}))}
          selectedStatuses={status}
          onSelectStatuses={onSelectStatuses}
          selectedTypes={cardType}
          onSelectTypes={onSelectTypes}
          nfcCards={nfcCards}
          totalCount={totalCount}
          fetching={fetching}
          onNext={onNext}
          onPrev={onPrev}
          handleChangePageSize={handleChangePageSize}
          refetch={refetch}
          getAllIds={getAllIds}
          getRowIdsOnPage={getRowIdsOnPage}
        />
      )}
      {tabId === 'employee' && (
        <EmployeeCards
          employeeCardOwnerIds={ownerIds}
          onChangeEmployeeCardOwnerIds={(ids: string[]) => setSearchState(prev => ({...prev, ownerIds: ids}))}
          searchWord={searchWord}
          onChangeSearchWord={(word: string) => setSearchState(prev => ({...prev, searchWord: word}))}
          nfcCards={nfcCards}
          totalCount={totalCount}
          fetching={fetching}
          onNext={onNext}
          onPrev={onPrev}
          handleChangePageSize={handleChangePageSize}
          refetch={refetch}
          getAllIds={getAllIds}
          getRowIdsOnPage={getRowIdsOnPage}
        />
      )}
      {tabId === 'lending' && (
        <LendingCards
          customerIds={customersIds}
          selectedStatuses={status}
          onSelectStatuses={onSelectStatuses}
          lendingPersonaIds={borrowerIds}
          onChangeLendingPersonaIds={(ids: string[]) => setSearchState(prev => ({...prev, borrowerIds: ids}))}
          searchWord={searchWord}
          onChangeSearchWord={(word: string) => setSearchState(prev => ({...prev, searchWord: word}))}
          groupIds={groupIds}
          onChangeGroupIds={(ids: string[]) => setSearchState(prev => ({...prev, groupIds: ids}))}
          nfcCards={nfcCards}
          totalCount={totalCount}
          fetching={fetching}
          onNext={onNext}
          onPrev={onPrev}
          handleChangePageSize={handleChangePageSize}
          refetch={refetch}
          getAllIds={getAllIds}
          getRowIdsOnPage={getRowIdsOnPage}
        />
      )}
      {tabId === 'memberCard' && (
        <MemberCards
          thirdPlaceUserGroupIds={thirdPlaceUserGroupIds}
          memberCardOwnerIds={ownerIds}
          onChangeMemberCardOwnerIds={(ids: string[]) => setSearchState(prev => ({...prev, ownerIds: ids}))}
          searchWord={searchWord}
          onChangeSearchWord={(word: string) => setSearchState(prev => ({...prev, searchWord: word}))}
          nfcCards={nfcCards}
          totalCount={totalCount}
          fetching={fetching}
          onNext={onNext}
          onPrev={onPrev}
          handleChangePageSize={handleChangePageSize}
          refetch={refetch}
          getAllIds={getAllIds}
          getRowIdsOnPage={getRowIdsOnPage}
        />
      )}

      <WNfcRegistrationDialog
        open={openManualDialog}
        setOpen={setOpenManualDialog}
        onClose={closeManualAddDialog}
        refetch={refetch}
        headerTabId={tabId}
      />
      <WNfcCardAddToGroupByCsvDialog open={openCsvDialog} onClose={closeCsvAddDialog} refetch={refetch} />
      <WNfcCardDownloadCsvDialog open={openDownloadCsvDialog} onClose={onCloseDownloadCsvDialog} />
    </Root>
  );
}

export type NfcCardNode = FetchNfcCardQueryQuery['nfcCardOffsetPagination']['nodes'][number];

interface CommonCardTableProps {
  nfcCards: NfcCardNode[] | undefined;
  totalCount: number | undefined;
  fetching: boolean;
  onNext: (page: number) => void;
  onPrev: (page: number) => void;
  handleChangePageSize: (_currentPageNumber: number, pageSize: number) => void;
  refetch: () => void;
  getAllIds: () => Promise<string[]>;
  getRowIdsOnPage: () => Promise<string[]>;
}

const allNfcCardDictDef = {
  searchableName: {
    default: {
      default: '名称、IDm、コード',
      [Locale.en_US]: 'Name, IDm, Code',
    },
  },
  status: {
    default: {
      default: 'ステータス',
      [Locale.en_US]: 'Status',
    },
  },
  inUse: {
    default: {
      default: '利用中',
      [Locale.en_US]: 'In use',
    },
  },
  notInUse: {
    default: {
      default: '利用なし',
      [Locale.en_US]: 'Not in use',
    },
  },
  lendable: {
    default: {
      default: '貸出可',
      [Locale.en_US]: 'Lendable',
    },
  },
  lending: {
    default: {
      default: '貸出中',
      [Locale.en_US]: 'Lending',
    },
  },
  exceeded: {
    default: {
      default: '期限超過',
      [Locale.en_US]: 'Exceeded',
    },
  },
  cardType: {
    default: {
      default: 'タイプ',
      [Locale.en_US]: 'Type',
    },
  },
  employeeIdCard: {
    default: {
      default: '社員証',
      [Locale.en_US]: 'Employee Id Card',
    },
  },
  lendingCard: {
    default: {
      default: '一時利用カード',
      [Locale.en_US]: 'Temporary Use Card',
    },
  },
  memberCard: {
    default: {
      default: '会員証',
      [Locale.en_US]: 'Member Card',
    },
  },
};
const AllNfcCards = React.memo<
  {
    useThirdPlace: boolean;
    searchWord: string;
    onChangeSearchWord: (word: string) => void;
    selectedStatuses: Status[];
    onSelectStatuses: (statuses: Status[]) => void;
    selectedTypes: V2CardType[];
    onSelectTypes: (types: V2CardType[]) => void;
  } & CommonCardTableProps
>(function AllNfcCards({
  useThirdPlace,
  searchWord: initialSearchWord,
  onChangeSearchWord,
  selectedStatuses,
  onSelectStatuses,
  selectedTypes,
  onSelectTypes,
  nfcCards,
  totalCount,
  fetching,
  onNext,
  onPrev,
  handleChangePageSize,
  refetch,
  getAllIds,
  getRowIdsOnPage,
}) {
  const dict = useDict(allNfcCardDictDef);
  const [searchWord, setSearchWord] = useDebouncedState(initialSearchWord, onChangeSearchWord);
  const allFacets = useMemo<WFacetSelectProps[]>(() => {
    const choices: {id: Status; label: string}[] = [
      {
        id: 'inUse',
        label: dict.inUse,
      },
      {
        id: 'notInUse',
        label: dict.notInUse,
      },
      {
        id: 'lendable',
        label: dict.lendable,
      },
      {
        id: 'lending',
        label: dict.lending,
      },
      {
        id: 'exceeded',
        label: dict.exceeded,
      },
    ];
    const _choices: {id: V2CardType; label: string}[] = [
      {
        id: V2CardType.Employee,
        label: dict.employeeIdCard,
      },
      {
        id: V2CardType.Lending,
        label: dict.lendingCard,
      },
    ];
    if (useThirdPlace)
      _choices.push({
        id: V2CardType.ThirdPlaceMemberCard,
        label: dict.memberCard,
      });

    return [
      {
        label: dict.status,
        choices,
        defaultCheckedIds: selectedStatuses,
        onCheckStateChanged: (ids: string[]) => {
          const selectedIds: Status[] = ids as Status[];
          onSelectStatuses(selectedIds);
        },
      },
      {
        label: dict.cardType,
        choices: _choices,
        defaultCheckedIds: selectedTypes,
        onCheckStateChanged: selectedIds => {
          onSelectTypes(selectedIds as V2CardType[]);
        },
      },
    ];
  }, [
    dict.cardType,
    dict.employeeIdCard,
    dict.exceeded,
    dict.inUse,
    dict.lendable,
    dict.lending,
    dict.lendingCard,
    dict.memberCard,
    dict.notInUse,
    dict.status,
    onSelectStatuses,
    onSelectTypes,
    selectedStatuses,
    selectedTypes,
    useThirdPlace,
  ]);

  return (
    <WNfcCardTable
      nfcCards={nfcCards}
      totalCount={totalCount}
      fetching={fetching}
      onNext={onNext}
      onPrev={onPrev}
      handleChangePageSize={handleChangePageSize}
      refetch={refetch}
      getAllIds={getAllIds}
      getRowIdsOnPage={getRowIdsOnPage}
      headerSearch={
        <WHeaderSearch
          searchableLabels={[dict.searchableName]}
          facets={allFacets}
          searchWord={searchWord}
          updateSearchWord={setSearchWord}
          searchBoxWidth={350}
        />
      }
    />
  );
});

const employeeDictDef = {
  owner: {
    default: {
      default: '所有者',
      [Locale.en_US]: 'Owner',
    },
  },
  ownerName: {
    default: {
      default: '所有者名',
      [Locale.en_US]: 'Owner Name',
    },
  },
  searchableNameForEmployee: {
    default: {
      default: '名称、IDm、コード',
      [Locale.en_US]: 'Name, IDm, Code',
    },
  },
};
const EmployeeCards = React.memo<
  {
    employeeCardOwnerIds: string[];
    onChangeEmployeeCardOwnerIds: (ids: string[]) => void;
    searchWord: string;
    onChangeSearchWord: (word: string) => void;
  } & CommonCardTableProps
>(function EmployeeCards({
  employeeCardOwnerIds,
  onChangeEmployeeCardOwnerIds,
  searchWord: initialSearchWord,
  onChangeSearchWord,
  nfcCards,
  totalCount,
  fetching,
  onNext,
  onPrev,
  handleChangePageSize,
  refetch,
  getAllIds,
  getRowIdsOnPage,
}) {
  const dict = useDict(employeeDictDef);
  const [searchWord, setSearchWord] = useDebouncedState(initialSearchWord, onChangeSearchWord);
  const employeeFacets = useMemo<WFacetSearchSelectModelsProps[]>(() => {
    const facets: WFacetSearchSelectModelsProps[] = [
      {
        model: 'people',
        visibleTypeIcon: true,
        label: dict.owner,
        searchableLabels: [dict.ownerName],
        checkType: 'multi',
        disable: false,
        selectedIds: employeeCardOwnerIds,
        filter: {
          type: {equals: 'Member'},
          employeeStatus: ['inTheJob'],
        },
        onCheckStateChanged: selectedIds => {
          onChangeEmployeeCardOwnerIds(selectedIds);
        },
      },
    ];
    return facets;
  }, [dict.owner, dict.ownerName, employeeCardOwnerIds, onChangeEmployeeCardOwnerIds]);

  return (
    <WNfcEmployeeCardTable
      type={V2CardType.Employee}
      nfcCards={nfcCards}
      totalCount={totalCount}
      fetching={fetching}
      onNext={onNext}
      onPrev={onPrev}
      handleChangePageSize={handleChangePageSize}
      refetch={refetch}
      getAllIds={getAllIds}
      getRowIdsOnPage={getRowIdsOnPage}
      headerSearch={
        <WHeaderSearch
          searchableLabels={[dict.searchableNameForEmployee]}
          facets={employeeFacets}
          searchWord={searchWord}
          updateSearchWord={setSearchWord}
          searchBoxWidth={350}
        />
      }
    />
  );
});

const lendingDictDef = {
  status: {
    default: {
      default: 'ステータス',
      [Locale.en_US]: 'Status',
    },
  },
  lendable: {
    default: {
      default: '貸出可',
      [Locale.en_US]: 'Lendable',
    },
  },
  lending: {
    default: {
      default: '貸出中',
      [Locale.en_US]: 'Lending',
    },
  },
  exceeded: {
    default: {
      default: '期限超過',
      [Locale.en_US]: 'Exceeded',
    },
  },
  user: {
    default: {
      default: '利用者',
      [Locale.en_US]: 'User',
    },
  },
  userName: {
    default: {
      default: '利用者名',
      [Locale.en_US]: 'User Name',
    },
  },
  searchableNameForLending: {
    default: {
      default: '名称、IDm、コード',
      [Locale.en_US]: 'Name, IDm, Code',
    },
  },
  group: {
    default: {
      default: 'カードグループ',
      [Locale.en_US]: 'Card Group',
    },
  },
  groupName: {
    default: {
      default: 'カードグループ名',
      [Locale.en_US]: 'Card Group Name',
    },
  },
};
const LendingCards = React.memo<
  {
    customerIds: string[];
    selectedStatuses: Status[];
    onSelectStatuses: (statuses: Status[]) => void;
    lendingPersonaIds: string[];
    onChangeLendingPersonaIds: (ids: string[]) => void;
    searchWord: string;
    onChangeSearchWord: (word: string) => void;
    groupIds: string[];
    onChangeGroupIds: (ids: string[]) => void;
  } & CommonCardTableProps
>(function LendingCards({
  customerIds,
  selectedStatuses,
  onSelectStatuses,
  lendingPersonaIds,
  onChangeLendingPersonaIds,
  searchWord: initialSearchWord,
  onChangeSearchWord,
  groupIds,
  onChangeGroupIds,
  nfcCards,
  totalCount,
  fetching,
  onNext,
  onPrev,
  handleChangePageSize,
  refetch,
  getAllIds,
  getRowIdsOnPage,
}) {
  const dict = useDict(lendingDictDef);
  const [searchWord, setSearchWord] = useDebouncedState(initialSearchWord, onChangeSearchWord);
  const lendingFacets = useMemo<(WFacetSearchSelectModelsProps | WFacetSelectProps)[]>(() => {
    const facets: (WFacetSearchSelectModelsProps | WFacetSelectProps)[] = [];
    facets.push({
      label: dict.status,
      choices: [
        {
          id: 'lendable',
          label: dict.lendable,
        },
        {
          id: 'lending',
          label: dict.lending,
        },
        {
          id: 'exceeded',
          label: dict.exceeded,
        },
      ],
      defaultCheckedIds: selectedStatuses,
      onCheckStateChanged: (ids: string[]) => {
        const selectedIds: Status[] = ids as Status[];
        onSelectStatuses(selectedIds);
      },
    });
    facets.push({
      model: 'people',
      visibleTypeIcon: true,
      label: dict.user,
      searchableLabels: [dict.userName],
      selectedIds: lendingPersonaIds,
      disable: false,
      checkType: 'multi',
      onCheckStateChanged: onChangeLendingPersonaIds,
    });
    facets.push({
      model: 'nfcCardGroup',
      label: dict.group,
      searchableLabels: [dict.groupName],
      selectedIds: groupIds,
      disable: false,
      checkType: 'multi',
      onCheckStateChanged: onChangeGroupIds,
    });
    return facets;
  }, [
    dict.status,
    dict.lendable,
    dict.lending,
    dict.exceeded,
    dict.user,
    dict.userName,
    dict.group,
    dict.groupName,
    selectedStatuses,
    lendingPersonaIds,
    groupIds,
    onChangeLendingPersonaIds,
    onChangeGroupIds,
    onSelectStatuses,
  ]);
  return (
    <WNfcLendingCardTable
      customersIds={customerIds}
      nfcCards={nfcCards}
      totalCount={totalCount}
      fetching={fetching}
      onNext={onNext}
      onPrev={onPrev}
      handleChangePageSize={handleChangePageSize}
      refetch={refetch}
      getAllIds={getAllIds}
      getRowIdsOnPage={getRowIdsOnPage}
      headerSearch={
        <WHeaderSearch
          searchableLabels={[dict.searchableNameForLending]}
          facets={lendingFacets}
          searchWord={searchWord}
          updateSearchWord={setSearchWord}
          searchBoxWidth={350}
        />
      }
    />
  );
});

const memberCardDictDef = {
  member: {
    default: {
      default: '会員',
      [Locale.en_US]: 'Member',
    },
  },
  memberName: {
    default: {
      default: '会員名',
      [Locale.en_US]: 'Member Name',
    },
  },
  searchableNameForMemberCard: {
    default: {
      default: '名称、IDm、コード',
      [Locale.en_US]: 'Name, IDm, Code',
    },
  },
};
const MemberCards = React.memo<
  {
    thirdPlaceUserGroupIds: string[];
    memberCardOwnerIds: string[];
    onChangeMemberCardOwnerIds: (ids: string[]) => void;
    searchWord: string;
    onChangeSearchWord: (word: string) => void;
  } & CommonCardTableProps
>(function MemberCards({
  thirdPlaceUserGroupIds,
  memberCardOwnerIds,
  onChangeMemberCardOwnerIds,
  searchWord: initialSearchWord,
  onChangeSearchWord,
  nfcCards,
  totalCount,
  fetching,
  onNext,
  onPrev,
  handleChangePageSize,
  refetch,
  getAllIds,
  getRowIdsOnPage,
}) {
  const dict = useDict(memberCardDictDef);
  const [searchWord, setSearchWord] = useDebouncedState(initialSearchWord, onChangeSearchWord);
  const memberCardFacet = useMemo<WFacetSearchSelectModelsProps[]>(() => {
    const facets: WFacetSearchSelectModelsProps[] = [];
    facets.push({
      model: 'people',
      visibleTypeIcon: true,
      label: dict.member,
      searchableLabels: [dict.memberName],
      disable: false,
      checkType: 'multi',
      selectedIds: memberCardOwnerIds,
      filter: {
        userGroupIds: thirdPlaceUserGroupIds,
        type: {equals: 'Customer'},
      },
      onCheckStateChanged: onChangeMemberCardOwnerIds,
    });
    return facets;
  }, [dict.member, dict.memberName, memberCardOwnerIds, thirdPlaceUserGroupIds, onChangeMemberCardOwnerIds]);
  return (
    <WNfcEmployeeCardTable
      type={V2CardType.ThirdPlaceMemberCard}
      nfcCards={nfcCards}
      totalCount={totalCount}
      fetching={fetching}
      onNext={onNext}
      onPrev={onPrev}
      handleChangePageSize={handleChangePageSize}
      refetch={refetch}
      getAllIds={getAllIds}
      getRowIdsOnPage={getRowIdsOnPage}
      headerSearch={
        <WHeaderSearch
          searchableLabels={[dict.searchableNameForMemberCard]}
          facets={memberCardFacet}
          searchWord={searchWord}
          updateSearchWord={setSearchWord}
          searchBoxWidth={350}
        />
      }
    />
  );
});

const FetchNfcCardQuery = graphql(`
  query FetchNfcCardQuery(
    $organizationId: String!
    $skip: Int!
    $take: Int!
    $freeWord: String
    $ownerId: IdInput
    $borrowerId: IdInput
    $groupId: IdInput
    $status: [NfcCardStatus!]
    $type: NfcCardTypeInput
  ) {
    nfcCardOffsetPagination(
      organizationId: $organizationId
      skip: $skip
      take: $take
      freeWord: $freeWord
      ownerId: $ownerId
      borrowerId: $borrowerId
      groupId: $groupId
      status: $status
      type: $type
    ) {
      totalCount
      nodes {
        id
        ...NfcCardTableRowFragment
        ...NfcEmployeeCardTableRowFragment
        ...NfcLendingCardTableRowFragment
      }
    }
  }
`);

const useNfcCardQuery = (args: {
  organizationId: string;
  searchWord: string | undefined;
  type: NfcCardTypeInput | undefined;
  ownerId: IdInput | undefined;
  borrowerId: IdInput | undefined;
  groupId: IdInput | undefined;
  status: NfcCardStatus[] | undefined;
  skip: number;
  take: number;
}) => {
  const {organizationId, searchWord, type, ownerId, borrowerId, status, groupId, skip, take} = args;
  const [result, refetch] = useQuery({
    query: FetchNfcCardQuery,
    variables: {
      organizationId,
      freeWord: searchWord,
      type,
      ownerId,
      borrowerId,
      groupId,
      status,
      skip,
      take,
    },
    requestPolicy: 'cache-and-network',
  });

  const nfcCards = useMemo(() => {
    if (!result.data) return;
    return result.data.nfcCardOffsetPagination.nodes;
  }, [result]);

  const totalCount = useMemo(() => result.data?.nfcCardOffsetPagination.totalCount, [result]);

  return {nfcCards, totalCount, fetching: result.fetching, refetch};
};

const FetchNfcCardIdsQuery = graphql(`
  query FetchNfcCardIdsQuery(
    $organizationId: String!
    $skip: Int!
    $take: Int!
    $freeWord: String
    $ownerId: IdInput
    $borrowerId: IdInput
    $groupId: IdInput
    $status: [NfcCardStatus!]
    $type: NfcCardTypeInput
  ) {
    nfcCardOffsetPagination(
      organizationId: $organizationId
      skip: $skip
      take: $take
      freeWord: $freeWord
      ownerId: $ownerId
      borrowerId: $borrowerId
      groupId: $groupId
      status: $status
      type: $type
    ) {
      totalCount
      nodes {
        id
      }
    }
  }
`);

const useAllNfcCardIds = (args: {
  organizationId: string;
  searchWord: string | undefined;
  type: NfcCardTypeInput | undefined;
  ownerId: IdInput | undefined;
  borrowerId: IdInput | undefined;
  groupId: IdInput | undefined;
  status: NfcCardStatus[] | undefined;
}): (() => Promise<string[]>) => {
  const {organizationId, searchWord, type, ownerId, borrowerId, status, groupId} = args;
  const client = useClient();
  const perPage = 200;
  return useCallback(async () => {
    const query = async (skip: number): Promise<string[]> => {
      const result = await client
        .query(
          FetchNfcCardIdsQuery,
          {
            skip: skip,
            take: perPage,
            organizationId,
            freeWord: searchWord,
            type,
            ownerId,
            borrowerId,
            groupId,
            status,
          },
          {requestPolicy: 'network-only'}
        )
        .toPromise();
      if (!result.data) {
        return [];
      }

      const {totalCount, nodes} = result.data.nfcCardOffsetPagination;
      const ids = ArrayUtil.removeUndefined(nodes.map(node => node.id));
      if (totalCount > ids.length + skip) {
        const nextResult = await query(skip + perPage);
        return [...ids, ...nextResult];
      } else {
        return ids;
      }
    };
    return query(0);
  }, [client, organizationId, searchWord, type, ownerId, borrowerId, groupId, status]);
};
