import {createContext, type FC, type PropsWithChildren, useContext, useMemo} from 'react';

import type {Feature, FeatureGroup} from '@/common/feature-control';
import {useLoginUser} from '@/common/hooks/useLoginUser';
import {useOrganization} from '@/common/hooks/useOrganization';

import {FeatureControlHelper} from './helpers/featureControlHelper';

type Context = {
  featureGroups: Set<FeatureGroup>;
  features: Set<Feature | 'accessControlV1' | 'accessControlV2'>;
};

const defaultValue: Context = {
  featureGroups: new Set(),
  features: new Set(),
};

const FeatureControlContext = createContext<Context>(defaultValue);

/**
 * 現在ログインしているユーザが利用可能な機能の一覧を取得し, コンテキストに保持する.
 * useFeature 等を利用するために必要.
 */
export const FeatureControlProvider: FC<PropsWithChildren> = ({children}) => {
  const {superUser, activations, authority} = useLoginUser();
  const {tempAccessControlVersionSettings} = useOrganization();
  const activationGroups = activations.map(a => a.activationGroup);

  const helper = useMemo(() => FeatureControlHelper.createInstance(superUser), [superUser]);
  const featureGroups = useMemo(() => helper.getActivatedFeatureGroups(activationGroups), [helper, activationGroups]);
  const features = useMemo(
    () => [
      ...helper.getAuthorizedFeatures(authority),
      // アクセスコントロールのバージョンで利用可能か変わる機能もこの feature-control で扱えるよう擬似的に追加する
      tempAccessControlVersionSettings?.useV2 ? ('accessControlV2' as const) : ('accessControlV1' as const),
    ],
    [helper, authority, tempAccessControlVersionSettings?.useV2]
  );

  const value = useMemo(
    (): Context => ({
      featureGroups: new Set(featureGroups),
      features: new Set(features),
    }),
    [featureGroups, features]
  );

  return <FeatureControlContext.Provider value={value}>{children}</FeatureControlContext.Provider>;
};

type Condition<T> = T | {AND: ReadonlyArray<T>} | {OR: ReadonlyArray<T>};

const satisfiesCondition = <T,>(condition: Condition<T>, query: (value: T) => boolean) => {
  if (condition && typeof condition === 'object') {
    if ('OR' in condition) {
      return condition.OR.some(query);
    }

    if ('AND' in condition) {
      return condition.AND.every(query);
    }
  }

  return query(condition);
};

export type UseFeatureConfig =
  | {featureGroup: Condition<FeatureGroup>}
  | {feature: Condition<Feature | 'accessControlV1' | 'accessControlV2'>};

type UseFeature = {
  enabled: boolean;
};

/**
 * 機能が利用可能かを検証する.
 * 複数の機能に対する AND, OR 条件も利用可能.
 */
export const useFeature = (config: UseFeatureConfig): UseFeature => {
  const {featureGroups, features} = useContext(FeatureControlContext);

  if ('featureGroup' in config) {
    if (!satisfiesCondition(config.featureGroup, f => featureGroups.has(f))) {
      return {enabled: false};
    }
  }

  if ('feature' in config) {
    if (!satisfiesCondition(config.feature, f => features.has(f))) {
      return {enabled: false};
    }
  }

  return {enabled: true};
};
