import {createBrowserHistory, createRouter, type ErrorRouteComponent, RouterProvider} from '@tanstack/react-router';
import type {FC} from 'react';

import {routeTree} from './routeTree.gen';

/**
 * モジュールの動的読み込み時に発生したエラーかを判定する.
 */
function isModuleLoadingError(error: Error): boolean {
  if (!error.message) return false;
  return (
    error.message.includes('Failed to fetch dynamically imported module') || // Chrome
    error.message.includes('error loading dynamically imported module') || // Firefox
    error.message.includes('Importing a module script failed') // Safari
  );
}

/**
 * TanStack Router の内側でエラーが起きた時にデフォルトのエラー画面を表示しないようにする
 * 例えばモジュールロード関連のエラーは Vite が捕捉して vite:preloadError イベントを発行する仕組みがあるため, そちらを利用する.
 *
 * FIXME: 一方でユーザにとっては真っ白な画面が表示されることがあるので, 復帰不能なエラーが発生したときのエラー画面を用意しておいたほうが良い
 */
const ErrorBoundary: ErrorRouteComponent = ({error}): null => {
  // FIXME: TanStack Router を介すると vite:preloadError が正しく伝播しないことが分かっている.
  //        そのためモジュール読み込み時のエラーであると判定したらここで vite:preloadError を別途発行する.
  if (isModuleLoadingError(error)) {
    // https://github.com/vitejs/vite/blob/f19ffbcf3111df45206e95909592b2b75ec76e10/packages/vite/src/node/plugins/importAnalysisBuild.ts#L150-L154
    const event = new Event('vite:preloadError', {cancelable: true}) as VitePreloadErrorEvent;
    event.payload = error;
    window.dispatchEvent(event);
  }

  return null;
};

export const history = createBrowserHistory();

const router = createRouter({
  history,
  routeTree,
  defaultErrorComponent: ErrorBoundary,
});

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }

  interface HistoryState {
    [key: string]: unknown;
  }
}

export const Router: FC = () => {
  return <RouterProvider router={router} />;
};
