import axios, {AxiosError, type AxiosInstance, type AxiosResponse, type InternalAxiosRequestConfig} from 'axios';

import {getAccessToken} from '@/common/accessToken';
import {getIdTokenResult} from '@/common/firebase/firebase-auth';
import {FirebaseFunctions} from '@/common/firebase/functions/firebase-functions';
import Logger from '@/common/logger/logger';

/**
 * Firebase Authn の ID トークンと BKP のアクセストークン (こちらは互換性のため) を取得して, リクエストのヘッダに追加する.
 * Axios の Interceptor として利用する想定.
 */
async function withAuthenticationHeaders(config: InternalAxiosRequestConfig): Promise<InternalAxiosRequestConfig> {
  const accessToken = getAccessToken();
  const idToken = await getIdTokenResult().then(res => res?.token);

  return {
    ...config,
    headers: config.headers.concat({
      ...(accessToken ? {'x-api-key': accessToken} : {}),
      ...(idToken ? {authorization: `bearer ${idToken}`} : {}),
    }),
  };
}

function handleResponse(logger: Logger): (response: AxiosResponse) => AxiosResponse {
  return function (response) {
    logger.trace(response.request?.responseURL, response);

    return response;
  };
}

function handleErrorResponse(logger: Logger): (e: unknown) => never {
  return function (e) {
    const error = e as AxiosError<{code: number}>;
    const errRes = error.response;
    if (errRes?.data && errRes.data.code === 401 && FirebaseFunctions.logoutByExpired) {
      logger.info(`failed to authenticate. logouting...`, {res: errRes}, error);
      setTimeout(() => {
        // 一応次のキューに詰んで最低限state更新後に実行されるようにする
        FirebaseFunctions.logoutByExpired?.();
      });
    } else {
      logger.error(`failed to ${error.response?.config.method} ${error.response?.config.url}`, error);
    }

    throw e;
  };
}

/** 未認証の API クライアントを作成する. */
export function createClient(name: string, baseUrl: URL): AxiosInstance {
  const logger = Logger.create(name);
  const client = axios.create({
    baseURL: baseUrl.toString(),
    headers: {
      'content-type': 'application/json',
    },
  });

  client.interceptors.response.use(handleResponse(logger), handleErrorResponse(logger));

  return client;
}

/** 認証済みの API クライアントを作成する. */
export function createAuthenticatedClient(name: string, baseUrl: URL): AxiosInstance {
  const client = createClient(name, baseUrl);

  client.interceptors.request.use(withAuthenticationHeaders);

  return client;
}
