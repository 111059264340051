import type {AxiosRequestConfig} from 'axios';

import {createAuthenticatedClient} from '@/api/createClient';
import {axiosCatch} from '@/common/error/axiosError';
import {getStackTrace} from '@/common/error/stacktrace';

const client = createAuthenticatedClient('account', new URL('./workhubAccount/v1', import.meta.env.VITE_API_HOST));

export class ApiAccount {
  static post = async <Response, Request>(
    path: string,
    data: Request,
    config?: AxiosRequestConfig
  ): Promise<Response> => {
    const res = await client.post(path, data, config).catch(axiosCatch(getStackTrace()));
    return res.data;
  };

  static patch = async <Response, Request>(
    path: string,
    data: Request,
    config?: AxiosRequestConfig
  ): Promise<Response> => {
    const res = await client.patch(path, data, config).catch(axiosCatch(getStackTrace()));
    return res.data;
  };

  static get = async <Response>(path: string, config?: AxiosRequestConfig): Promise<Response> => {
    const res = await client.get(path, config).catch(axiosCatch(getStackTrace()));
    return res.data;
  };

  static delete = async <Response>(path: string, config?: AxiosRequestConfig): Promise<Response> => {
    const res = await client.delete(path, config).catch(axiosCatch(getStackTrace()));
    return res.data;
  };
}
