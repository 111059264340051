import {FirestoreDocumentPath} from '@bitkey-service/v2_core-types/lib/common/firestoreDocumentPath';
import {TimestampToEpochMillis} from '@bitkey-service/workhub-functions-common-types/lib/common/typeUtils';
import {FirestoreCollectionPath} from '@bitkey-service/workhub-functions-common-types/lib/firestore/firestoreCollectionPath';
import {V2CoreData} from '@bitkey-service/workhub-functions-common-types/lib/firestore/v2_coreDataTypes';
import {
  CollectionReference,
  DocumentReference,
  Firestore as FirestoreApp,
  FirestoreDataConverter,
  Query,
  QueryDocumentSnapshot,
  Timestamp as TimestampFs,
  collection,
  connectFirestoreEmulator,
  doc,
  documentId,
  getCountFromServer,
  getDoc,
  getDocs,
  getFirestore,
  onSnapshot,
  query,
  where,
} from 'firebase/firestore';

import Logger, {DeCycleStringify} from '../../logger/logger';
import {EpochMillis} from '../../types/common-types';
import {getFirebaseApp} from '../firebase';

let firestore: FirestoreApp | undefined;
const getFirebaseFirestore = (): FirestoreApp => {
  if (firestore) {
    return firestore;
  }
  firestore = getFirestore(getFirebaseApp());
  if (import.meta.env.VITE_FIREBASE_PROJECT_ID === 'workhub-local') {
    if (import.meta.env.VITE_FIRESTORE_EMULATOR_HOST) {
      const [host, portStr] = import.meta.env.VITE_FIRESTORE_EMULATOR_HOST.split(':');
      if (host && portStr) {
        const port = parseInt(portStr);
        connectFirestoreEmulator(firestore, host, port);
      }
    }
  }
  return firestore;
};

const logger = Logger.create('Firestore');
export type Timestamp = TimestampFs;

const timestampToMillis = <T>(data: T): TimestampToEpochMillis<T> => {
  if (data instanceof Array) {
    return data.map(d => timestampToMillis(d)) as TimestampToEpochMillis<T>;
  } else if (data instanceof TimestampFs) {
    return data.toMillis() as TimestampToEpochMillis<T>;
  } else if (isRecord(data)) {
    // たまーーーにtimestampが壊れてるので
    if ((data as any as {nanoseconds: number}).nanoseconds >= 0 && (data as any as {seconds: number}).seconds >= 0) {
      const d = data as any as {nanoseconds: number; seconds: number};
      return (d.seconds * 1000 + d.nanoseconds / 1000000) as TimestampToEpochMillis<T>;
    }
    return Object.entries(data).reduce(
      (previousValue, [k, v]) => {
        return {...previousValue, [k]: v instanceof TimestampFs ? v.toMillis() : timestampToMillis(v)};
      },
      {} satisfies Record<string, unknown>
    ) as TimestampToEpochMillis<T>;
  } else {
    return data as TimestampToEpochMillis<T>;
  }
};

const isRecord = (obj: unknown): obj is Record<string, any> => {
  return Object.prototype.toString.call(obj) === '[object Object]';
};

const converter: FirestoreDataConverter<any> = {
  toFirestore: a => a,
  fromFirestore: snapshot => ({...timestampToMillis(snapshot.data()), id: snapshot.id}),
};

export class Firestore {
  private static instance: Firestore;
  public static getInstance = () => {
    if (!Firestore.instance) {
      Firestore.instance = new Firestore();
    }
    return Firestore.instance;
  };

  public static fromMills = (mills: number) => TimestampFs.fromMillis(mills);

  public getRandomId = () => doc(collection(getFirebaseFirestore(), 'organizations')).id;

  /**
   * @deprecated 自由だけど型とれなかったので作り直し。レイアウトで雑に使ってるので移行したら削除
   * @param path
   */
  public collection = (path: string) => {
    return collection(getFirebaseFirestore(), path);
  };

  public getCollectionRef = <T extends {id: string}>(path: FirestoreCollectionPath<T>) => {
    return collection(getFirebaseFirestore(), path.getPath()) as CollectionReference<T>;
  };

  public toDeletedCollectionReference = <T extends V2CoreData>(collectionRef: CollectionReference<T>) => {
    if (collectionRef.parent) {
      return collection(collectionRef.parent, collectionRef.id + '_deleted') as CollectionReference<T>;
    } else {
      return collection(getFirebaseFirestore(), collectionRef.id + '_deleted') as CollectionReference<T>;
    }
  };

  public getSpecificDoc = <T extends V2CoreData>(docPath: FirestoreDocumentPath<T>) =>
    doc(getFirebaseFirestore(), docPath.getPath()) as DocumentReference<T>;

  public documentIdField = () => documentId();

  public getByQuery = async <T extends {id: string}>(ref: Query<T>): Promise<TimestampToEpochMillis<T>[]> => {
    const res = ref.withConverter<TimestampToEpochMillis<T>>(converter);
    const snapshot = await getDocs(res);
    const data = snapshot.docs.map(d => d.data());
    const typed = ref as any as {
      _delegate: {
        _query: {
          path?: {
            segments?: string[];
          };
          filters?: {
            field?: {
              segments?: string[];
            };
            op?: string;
            value?: any;
          }[];
        };
      };
    };
    logger.trace(
      'getByQuery',
      typed._delegate?._query?.path?.segments?.join('/'),
      typed._delegate?._query?.filters
        ?.map(f => `${f.field?.segments?.join('.')}, '${f.op}', ${DeCycleStringify(f.value)}`)
        .join(' && '),
      data
    );
    return data;
  };

  public get = async <T extends {id: string}>(
    ref: DocumentReference<T>
  ): Promise<TimestampToEpochMillis<T> | undefined> => {
    const snapshot = await getDoc(ref.withConverter<TimestampToEpochMillis<T>>(converter));
    return snapshot.data();
  };

  public getById = async <T extends {id: string}>(
    ref: CollectionReference<T>,
    id: string
  ): Promise<TimestampToEpochMillis<T> | undefined> => {
    const snapshot = await getDoc(doc(ref, id).withConverter<TimestampToEpochMillis<T>>(converter));
    return snapshot.data();
  };

  // 毎回 ArrayUtil.removeEmptyとかArrayUtil.duplicateBySetとか呼び出したくないのでこっちでケアしてみる
  public getByIds = async <T extends {id: string}>(
    ref: CollectionReference<T>,
    ids: (string | undefined)[]
  ): Promise<TimestampToEpochMillis<T>[]> => {
    const uniqueIds = Array.from(new Set(ids.filter(id => !!id))) as string[];
    if (!uniqueIds.length) {
      logger.trace(`id-array is empty.`);
      return [];
    }

    const idLists = Array.from({length: Math.ceil(uniqueIds.length / 10)}).map((_, idx) =>
      uniqueIds.slice(idx * 10, (idx + 1) * 10)
    );

    return (
      await Promise.all(
        idLists.map(async ids => {
          return Firestore.getInstance().getByQuery(
            query(ref, where(Firestore.getInstance().documentIdField(), 'in', ids))
          );
        })
      )
    ).flatMap(s => s);
  };

  public onCollectionSnapshot = <T extends {id: string}>(ref: Query<T>, cb: (d: TimestampToEpochMillis<T>[]) => any) =>
    onSnapshot(ref.withConverter<TimestampToEpochMillis<T>>(converter), snapshot => {
      cb(snapshot.docs.map(d => d.data()));
    });

  public onDocumentSnapshot = <T extends {id: string}>(
    ref: DocumentReference<T>,
    cb: (d: TimestampToEpochMillis<T>) => void
  ) =>
    onSnapshot(ref.withConverter<TimestampToEpochMillis<T>>(converter), snapshot => {
      const data = snapshot.data();
      if (data) {
        cb(data);
      }
    });

  /**
   * ドキュメントのデータと最後のDocumentSnapshotを返す
   * @param ref
   */
  public getWithSnapshotByQuery = async <T extends {id: string}>(
    ref: Query<T>
  ): Promise<{
    data: TimestampToEpochMillis<T>[];
    lastSnapshot: QueryDocumentSnapshot<TimestampToEpochMillis<T>> | undefined;
  }> => {
    const q = ref.withConverter<TimestampToEpochMillis<T>>(converter);
    const snapshot = await getDocs(q);
    //5000件など大量に取得している場合はひたすらに重いので省略。これでも重ければ1000件から減らしてください
    //logger.trace('getWithSnapshotByQuery', res);
    logger.trace('getWithSnapshotByQuery', snapshot.docs.length < 1000 ? snapshot : snapshot.docs.length);
    return {
      data: snapshot.docs.map(d => d.data()),
      lastSnapshot: snapshot.docs.at(-1),
    };
  };

  public static timestampFromMillis = (millis: EpochMillis) => TimestampFs.fromMillis(millis);
  public static timestampFromMillisIfNull = (millis?: EpochMillis) => {
    if (millis) {
      return TimestampFs.fromMillis(millis);
    } else {
      return undefined;
    }
  };

  /**
   * よくあるin/array-contains-anyの分割をする便利なやつ
   * 事前にIn句つけたり、Orderしたりするとクエリ制約引っかかるので注意
   */
  public static getByFieldValues = async <T extends {id: string}>(
    q: Query<T>,
    fieldName: string,
    values: (string | undefined)[],
    isFieldArray?: boolean
  ): Promise<TimestampToEpochMillis<T>[]> => {
    const uniqValues = Array.from(new Set(values.filter(i => !!i))) as string[];
    if (!uniqValues.length) {
      logger.trace(`value is empty.`, {
        q,
        fieldName,
        values,
        isFieldArray,
        uniqValues,
      });
      return [];
    }

    const results = await Promise.all(
      Array.from({length: Math.ceil(uniqValues.length / 10)})
        .map((_, idx) => uniqValues.slice(idx * 10, (idx + 1) * 10))
        .map(async part => {
          return await Firestore.getInstance().getByQuery(
            query(q, where(fieldName, isFieldArray ? 'array-contains-any' : 'in', part))
          );
        })
    );
    return results.flat();
  };

  /**
   * 参考: https://cloud.google.com/blog/ja/products/databases/aggregating-data-with-firestore
   */
  public count = async <T>(query: Query<T>): Promise<number> => {
    const snapshot = await getCountFromServer(query);
    return snapshot.data().count;
  };
}
